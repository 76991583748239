// All the diet calculations

import { UserProfile } from "../pages/Profile";
import { Nutriments } from "../types/foodType";
import { Meal, MealType } from "../types/mealType";
import { Ingredient } from "../types/recipeType";
import { calculateAge, round, roundToClosestMultipleOf5 } from "./utils";

export interface DailyCalorieDistribution {
    [key: string]: Nutriments;  // Allows dynamic indexing
    day1: Nutriments;
    day2: Nutriments;
    day3: Nutriments;
    day4: Nutriments;
    day5: Nutriments;
    day6: Nutriments;
    day7: Nutriments;
}

export interface UserDietInfo {
    bmr: number;
    tdee: number;
    calorieDailyIntake: number;
    dailyCalories: DailyCalorieDistribution;
    proteinIntake: number;
    fatIntake: number;
    carbIntake: number;
    waterIntake: number;
    fiberIntake: number;
    calorieSurplusDeficit: number;
    bmi: number;
    estimatedGoalTimeline: number;
}

export const CALORIES_FOR_ONE_KG_IN_WEEK = 7700
export const CALORIES_FOR_ONE_KG_IN_A_DAY = 1100

/**
 * Function to recalculate the nutritional values for a given weight.
 * @param nutriments - The object containing nutritional values per 100g.
 * @param weightBase - The base weight in grams.
 * @param weightGoal - The goal weight in grams.
 * @returns An object with recalculated nutritional values.
 */
export function recalculateNutritionalValues(
    nutriments: Nutriments,
    weightBase: number = 100,
    weightGoal: number): Nutriments {
    const factor = weightGoal / weightBase;
    return {
        calories: round(nutriments.calories * factor, 10),
        proteins: round(nutriments.proteins * factor, 10),
        fats: round(nutriments.fats * factor, 10),
        carbohydrates: round(nutriments.carbohydrates * factor, 10),
    };
}

export function recalculateIngredientNutrimentsQuantity(ingredient: Ingredient, weightGoal: number): Nutriments {
    return {
        calories: Math.round(ingredient.nutriments_100g.calories * weightGoal / 100),
        proteins: Math.round(ingredient.nutriments_100g.proteins * weightGoal / 100),
        fats: Math.round(ingredient.nutriments_100g.fats * weightGoal / 100),
        carbohydrates: Math.round(ingredient.nutriments_100g.carbohydrates * weightGoal / 100),
    }
}



function calculateBMR(profile: UserProfile): number {
    const { gender, weight, height, birthdate } = profile;
    const age = calculateAge(birthdate);

    // Mifflin-St Jeor equation
    if (gender === 'male') {
        return 10 * weight + 6.25 * height - 5 * age + 5;
    } else {
        return 10 * weight + 6.25 * height - 5 * age - 161;
    }
}

function calculateTDEE(bmr: number, activity_level: string): number {
    const activityFactors: { [key: string]: number } = {
        'sedentary': 1.2,
        'light': 1.375,
        'moderate': 1.55,
        'very_active': 1.725,
        'extremely_active': 1.9,
    };

    return bmr * (activityFactors[activity_level] || 1.2); // Default to sedentary if unknown
}

function calculateCalorieIntake(profile: UserProfile, tdee: number): number {
    const { weight, weight_goal, gain_per_week } = profile;
    const CALORIES_FOR_ONE_KG_IN_A_WEEK = 7700; // 1 kg de poids corporel équivaut environ à 7700 kcal

    if (weight < weight_goal && gain_per_week) {
        // Surplus calorique pour prise de poids
        const surplus = (gain_per_week * CALORIES_FOR_ONE_KG_IN_A_WEEK) / 7; // Convertir en surplus journalier
        return Math.round(tdee + surplus);
    } else if (weight > weight_goal) {
        // Déficit calorique pour perte de poids
        const deficit = (Number(gain_per_week) * CALORIES_FOR_ONE_KG_IN_A_WEEK) / 7; // Convertir en déficit journalier
        // console.log('Perte de poids', tdee, weight, weight_goal, Number(gain_per_week), deficit);
        return Math.round(tdee + deficit);
    } else {
        // Calories de maintien
        return Math.round(tdee);
    }
}

function calculateMacros(calories: number): { protein: number, fat: number, carbs: number } {
    const proteinCalories = 0.25 * calories; // 30% protein
    const fatCalories = 0.25 * calories; // 25% fat
    const carbCalories = calories - proteinCalories - fatCalories; // Remaining for carbs

    return {
        protein: Math.round(proteinCalories / 4), // 4 calories per gram of protein
        fat: Math.round(fatCalories / 9), // 9 calories per gram of fat
        carbs: Math.round(carbCalories / 4) // 4 calories per gram of carbs
    };
}

function calculateBMI(weight: number, height: number): number {
    // Height is in cm, so convert to meters first
    return weight / Math.pow(height / 100, 2);
}

function calculateWaterIntake(weight: number): number {
    // Recommend 35-40 ml of water per kilogram of body weight
    return (weight * 35) / 1000; // in liters
}

function calculateFiberIntake(): number {
    // General recommendation: 25-30 grams of fiber daily
    return 30; // Can be adjusted if personalized recommendations are needed
}

function calculateGoalTimeline(profile: UserProfile, calorieIntake: number, tdee: number): number {
    const { weight_goal, gain_per_week, weight } = profile;

    const weeksToGoal = Math.ceil((weight_goal - weight) / gain_per_week);

    return Math.round(weeksToGoal);
}

function calculateCalorieSurplusDeficit(tdee: number, calorieIntake: number): number {
    return calorieIntake - tdee; // Positive for surplus, negative for deficit
}

function calculateMacrosBasedOnCalories(calories: number): Nutriments {
    const proteinCalories = 0.3 * calories; // 30% protein
    const fatCalories = 0.25 * calories; // 25% fat
    const carbCalories = calories - proteinCalories - fatCalories; // Remaining for carbs

    return {
        calories: roundToClosestMultipleOf5(calories),
        proteins: roundToClosestMultipleOf5(proteinCalories / 4), // 4 calories per gram of protein
        fats: roundToClosestMultipleOf5(fatCalories / 9), // 9 calories per gram of fat
        carbohydrates: roundToClosestMultipleOf5(carbCalories / 4) // 4 calories per gram of carbs
    };
}

function calculateProgressiveCalories(profile: UserProfile, tdee: number, dailyCalories: number): DailyCalorieDistribution {
    // const { activity_level } = profile;

    // Base calories for each day
    const baseCalories = new Array(7).fill(dailyCalories).map((calories, index) => {
        return roundToClosestMultipleOf5(calories);
    });
    // const activityBoost = activity_level === 'very_active' ? 200 : activity_level === 'moderate' ? 100 : 0;

    // Example of slightly altering calories based on activity: 
    // baseCalories[0] -= activityBoost; // Rest day
    // baseCalories[1] += activityBoost; // Active day
    // baseCalories[3] += activityBoost; // Another active day

    // Calculate macros and assign to each day
    return {
        day1: calculateMacrosBasedOnCalories(baseCalories[0]),
        day2: calculateMacrosBasedOnCalories(baseCalories[1]),
        day3: calculateMacrosBasedOnCalories(baseCalories[2]),
        day4: calculateMacrosBasedOnCalories(baseCalories[3]),
        day5: calculateMacrosBasedOnCalories(baseCalories[4]),
        day6: calculateMacrosBasedOnCalories(baseCalories[5]),
        day7: calculateMacrosBasedOnCalories(baseCalories[6])
    };
}

function getSplitedRatios(numMeals: number): { [key: string]: number } {
    switch (numMeals) {
        case 3:
            return {
                BREAKFAST: 0.30,
                LUNCH: 0.35,
                DINNER: 0.35,
            };

        case 4:
            return {
                BREAKFAST: 0.25,
                SNACK_1: 0.15,
                LUNCH: 0.30,
                DINNER: 0.30,
            };

        case 5:
            return {
                BREAKFAST: 0.20,
                SNACK_1: 0.10,
                LUNCH: 0.30,
                SNACK_2: 0.10,
                DINNER: 0.30,
            };

        case 6:
            return {
                BREAKFAST: 0.15,
                SNACK_1: 0.05,
                LUNCH: 0.30,
                SNACK_2: 0.10,
                DINNER: 0.30,
                SNACK_3: 0.05,
            };

        default:
            throw new Error('Invalid number of meals');
    }
}

function calculateMacrosRequiredForMeal(macros: Nutriments, caloriesRatio: number): Nutriments {
    return calculateMacrosBasedOnCalories(macros.calories * caloriesRatio)
}

// Generate empty meals depeding on the number of meals during the day
// dayMacros is an object with macros for the day
// numMeals is the number of meals in the day
// day is the date of the day
export function getEmptyMeals(dayMacros: Nutriments, numMeals: number, day: Date): { [key in MealType]?: Meal } {
    // Define default ratios for each meal type
    const ratios = getSplitedRatios(numMeals);

    // Calculate macros for each meal based on the ratio
    const meals: { [key in MealType]?: Meal } = {};

    for (const [mealName, ratio] of Object.entries(ratios)) {

        meals[mealName as MealType] = {
            type: mealName as MealType,
            date: day,
            recipe_name: '',
            is_optimized: false,
            is_eaten: false,
            recipes: [],
            macrosRequired: calculateMacrosRequiredForMeal(dayMacros, ratio),
            calculated_macros: {
                calories: 0,
                proteins: 0,
                fats: 0,
                carbohydrates: 0
            },
            ingredients: [],
            last_modified: new Date(0)
        }
    }

    return meals;
}

// Create meal planning with Meal for every day
// numMealsPerDay is the number of meals per day
// days is an array of dates
// @returns an object with meal planning for each day
export function createMealPlanning(
    dietInfo: UserDietInfo,
    numMealsPerDay: number,
    days: Date[]
): { [day: string]: { [key in MealType]?: Meal } } {

    // Initialize an empty planning object
    const planning: { [day: string]: { [key in MealType]?: Meal } } = {};

    days.forEach((day) => {
        const dayKey = day.toISOString().split('T')[0]; // Use ISO string format for day key (yyyy-mm-dd)

        // Retrieve the macros for the day from dietInfo (e.g., calories, proteins, fats, carbs)
        const dayMacros = {
            calories: dietInfo.dailyCalories.day1.calories,  // You can change this based on day (e.g., day1, day2, etc.)
            proteins: dietInfo.dailyCalories.day1.proteins,
            fats: dietInfo.dailyCalories.day1.fats,
            carbohydrates: dietInfo.dailyCalories.day1.carbohydrates,
        };

        // Generate empty meals for the day
        const mealsForDay = getEmptyMeals(dayMacros, numMealsPerDay, day);

        // Assign generated meals to the current day in the planning object
        planning[dayKey] = mealsForDay;
    });

    return planning;
}

export function getBMIReview(bmi: number): string {
    if (bmi < 18.5) {
        return 'En sous-poids';
    } else if (bmi >= 18.5 && bmi < 24.9) {
        return 'Poids normal';
    } else if (bmi >= 25 && bmi < 29.9) {
        return 'Surpoids';
    } else if (bmi >= 30 && bmi < 34.9) {
        return 'Obesité (Classe 1)';
    } else if (bmi >= 35 && bmi < 39.9) {
        return 'Obesité (Classe 2)';
    } else {
        return 'Obesité (Classe 3)';
    }
}




export function getUserDietInfo(profile: UserProfile): UserDietInfo {
    const bmr = calculateBMR(profile);
    const tdee = calculateTDEE(bmr, profile.activity_level);
    const calorieDailyIntake = calculateCalorieIntake(profile, tdee);
    const { protein, fat, carbs } = calculateMacros(calorieDailyIntake);
    const waterIntake = calculateWaterIntake(profile.weight);
    const fiberIntake = calculateFiberIntake();
    const bmi = calculateBMI(profile.weight, profile.height);
    const calorieSurplusDeficit = calculateCalorieSurplusDeficit(tdee, calorieDailyIntake);
    const estimatedGoalTimeline = calculateGoalTimeline(profile, calorieDailyIntake, tdee);

    // Calculate progressive calorie repartition for the week
    const dailyCalories = calculateProgressiveCalories(profile, tdee, calorieDailyIntake);

    return {
        bmr,
        tdee,
        calorieDailyIntake,
        dailyCalories, // Object with daily calorie and macro breakdown
        proteinIntake: protein,
        fatIntake: fat,
        carbIntake: carbs,
        waterIntake,
        fiberIntake,
        calorieSurplusDeficit,
        bmi: round(bmi, 2),
        estimatedGoalTimeline
    };
}