import React, { useState } from 'react';
import { Recipe, Ingredient, RecipeType } from '../types/recipeType';
import FoodSearch from '../components/FoodSearch';
import { RadioButton } from '../components/WeekPlanGenerator';
import { IngredientCard } from '../pages/HandleRecipe';

interface RecipeFormProps {
    recipe: Recipe;
    setRecipe: React.Dispatch<React.SetStateAction<Recipe>>;
    onSubmit: () => void;
    isSaving: boolean;
    submitLabel: string;
}

const RecipeForm: React.FC<RecipeFormProps> = ({ recipe, setRecipe, onSubmit, isSaving, submitLabel }) => {
    const addIngredient = (ingredient: Ingredient) => {
        setRecipe((prevRecipe) => ({
            ...prevRecipe,
            ingredients: [...prevRecipe.ingredients, ingredient],
        }));
    };

    const updateIngredient = (updatedIngredient: Ingredient) => {
        setRecipe((prevRecipe) => ({
            ...prevRecipe,
            ingredients: prevRecipe.ingredients.map((ingredient) =>
                ingredient.fdc_id === updatedIngredient.fdc_id
                    ? { ...ingredient, ...updatedIngredient }
                    : ingredient
            ),
        }));
    };

    const removeIngredient = (fdc_id: number) => {
        setRecipe((prevRecipe) => ({
            ...prevRecipe,
            ingredients: prevRecipe.ingredients.filter((ingredient) => ingredient.fdc_id !== fdc_id),
        }));
    };

    const updateType = (newType: RecipeType) => {
        setRecipe({ ...recipe, type: newType });
    };

    const changeRecipeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRecipe({ ...recipe, name: event.target.value });
    };

    return (
        <div className="flex flex-col gap-4 md:p-8 bg-nutrimmy-bg">
            <div className="flex flex-col-reverse md:flex-row gap-8">
                <div className="md:w-2/5">
                    <FoodSearch addIngredient={addIngredient} />
                </div>
                <div className="md:w-3/5 border-gray-200 md:min-h-[500px]">
                    <div>
                        <input
                            type="text"
                            placeholder="Nom de la recette"
                            value={recipe.name}
                            onChange={changeRecipeName}
                            className="font-bold border border-rose-200 rounded-lg py-2 px-4 w-full"
                        />
                        <RadioButton
                            radioDisplay={['Petit dejeuner', 'Repas', 'Collation']}
                            radioValues={['BREAKFAST', 'MEAL', 'SNACK']}
                            selectedValue={recipe.type}
                            setSelectedValue={updateType}
                        />
                    </div>
                    <div className="flex flex-col gap-2 my-4">
                        {recipe.ingredients.map((ingredient) => (
                            <IngredientCard
                                key={ingredient.fdc_id}
                                ingredient={ingredient}
                                updateIngredient={updateIngredient}
                                removeIngredient={removeIngredient}
                            />
                        ))}
                        {recipe.ingredients.length === 0 && (
                            <p className="text-orange-500">Vous devez ajouter au moins un ingrédient.</p>
                        )}
                    </div>
                    {recipe.ingredients.length > 0 && (
                        <div
                            className={`bg-green-700 cursor-pointer transition duration-200 hover:bg-green-600 text-white font-bold py-2 px-8 rounded-lg w-fit ${
                                isSaving ? 'opacity-50' : ''
                            }`}
                            onClick={onSubmit}
                        >
                            {isSaving ? 'Chargement...' : submitLabel}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RecipeForm;