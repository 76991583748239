import React from 'react';
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { useUserProvider } from '../provider/UserProvider';
import { LoadingFullScreen } from './Loading';

const CustomGoogleLogin: React.FC<{ text: string, type?: string  }> = ({ text = 'signin_with', type = 'standard', ...props }) => {
  const { handleGoogleSuccess, handleGoogleFailure } = useUserProvider();
  const [loading, setLoading] = React.useState(false);
  // Using GoogleLogin component directly

  const handleSuccess = (credentialResponse: CredentialResponse) => {
    setLoading(true);
    handleGoogleSuccess(credentialResponse).finally(() => setLoading(false));
  };

  if (loading) {
    return <LoadingFullScreen text="Connexion en cours..." />
  }

  return (
    <GoogleOAuthProvider clientId="69074667909-pd746hmt697q0i51bt58hggj1hq27fge.apps.googleusercontent.com">
      <GoogleLogin
        onSuccess={handleSuccess}
        onError={handleGoogleFailure}
        size='large'
        shape='rectangular'
        locale='fr-FR'
        type={type as 'standard' | 'icon'} 
        text={text as 'continue_with' | 'signin_with' | 'signup_with'} 
        logo_alignment='center'
        theme='filled_black'
        // useOneTap
      />
    </GoogleOAuthProvider>
  );
};

export default CustomGoogleLogin;
