import React, { useEffect, useRef, useState } from 'react';
import { DayHeader, MealItem } from './Meal';  // Import the Meal component
import { createMealPlanning, UserDietInfo } from '../services/diet';
import { formatDate, getCurrentWeekDays, getWeekDays } from '../services/utils';
import Cookies from 'js-cookie';
import WeekPlanGenerator, { RadioButton } from './WeekPlanGenerator';
import { Meal, MEAL_TYPE_FR, MealType } from '../types/mealType';
import apiClient from '../services/apiClient';
import ShoppingList from '../pages/ShoppingList';
import { UserProfile } from '../pages/Profile';
import { emptyMeal, getLastCachedMealPlanning, MEAL_SCHEDULE } from '../services/mealService';
import { CACHE_KEY_MEAL_PLANNING, getCachedMeals, getMealCacheTimestamps } from '../services/cacheService';
import { useMealPlanning } from '../provider/MealPlanningProvider';
import { useUserProvider } from '../provider/UserProvider';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid';

const Planning: React.FC = () => {
    const { profile, dietInfo } = useUserProvider();
    const { activeWeek, changeWeek, currentWeekOffset, mealPlanning, setMealPlanning, mealPlanningProviderReady } = useMealPlanning();
    const dayRefs = useRef<(HTMLDivElement | null)[]>([]);
    const gridRef = useRef<HTMLDivElement>(null);
    const today = new Date();
    const [currentWatchedDay, setCurrentWatchedDay] = useState<string>(formatDate(today));

    const isSameDay = (day1: Date, day2: Date) =>
        day1.getDate() === day2.getDate() &&
        day1.getMonth() === day2.getMonth() &&
        day1.getFullYear() === day2.getFullYear();


    const handleMouseDown = (e: React.MouseEvent) => {
        const element = gridRef.current;
        if (element) {
            element.style.cursor = 'grabbing';
            let startX = e.pageX - element.offsetLeft;
            let scrollLeft = element.scrollLeft;

            const handleMouseMove = (event: MouseEvent) => {
                const x = event.pageX - element.offsetLeft;
                const walk = (x - startX) * 1.5; // Adjust scroll speed
                element.scrollLeft = scrollLeft - walk;
            };

            const handleMouseUp = () => {
                element.style.cursor = 'grab';
                window.removeEventListener('mousemove', handleMouseMove);
                window.removeEventListener('mouseup', handleMouseUp);
            };

            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        }
    };

    const debounceTimer = useRef<NodeJS.Timeout | null>(null);

    const handleScroll = () => {
        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current); // Clear the previous timer
        }

        debounceTimer.current = setTimeout(() => {
            if (gridRef.current) {
                const firstChild = gridRef.current.querySelector<HTMLDivElement>('.day-header');

                if (firstChild) {
                    const width = firstChild.getBoundingClientRect().width + 16; // Width of each column
                    const scrollLeft = gridRef.current.scrollLeft; // Current scroll position
                    const totalColumns = activeWeek.length;

                    // Determine the closest column
                    const closestColumn = Math.min(
                        Math.max(Math.round(scrollLeft / width), 0), // Round to the nearest column
                        totalColumns - 1 // Ensure it stays within bounds
                    );

                    // Snap the scroll to the closest column
                    gridRef.current.scrollTo({
                        left: closestColumn * width,
                        behavior: 'smooth',
                    });

                    // Update the current watched day
                    setCurrentWatchedDay(formatDate(new Date(activeWeek[closestColumn].getTime())));
                }
            }
        }, 50); // 200ms debounce delay
    };

    const scrollToDay = (index: number) => {
        if (gridRef.current) {
            // Get the width of one column
            const columnWidth = gridRef.current.querySelector('.day-header')?.getBoundingClientRect().width || 350; // Default to 350px
            const scrollLeft = index * columnWidth + 16 * (index);

            // Smooth scroll to the calculated position
            gridRef.current.scrollTo({
                left: scrollLeft,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        const currentDayIndex = activeWeek.findIndex((day) => isSameDay(day, today));
        if (currentDayIndex !== -1) {
            scrollToDay(currentDayIndex);
        } else {
            // console.log("currentDayIndex", currentDayIndex, formatDate(activeWeek[0]));
            setCurrentWatchedDay(formatDate(activeWeek[0]));
            scrollToDay(0);
        }
    }, [activeWeek]);

    return (
        <div className='md:pt-14 bg-white'>
            {/* <div className='hidden md:block md:mb-8 pl-4'> */}

            {/* <RadioButton radioDisplay={['Cette semaine', 'Semaine prochaine']} radioValues={[0, 1]} selectedValue={currentWeekOffset} setSelectedValue={changeWeek} /> */}
            {/* <h2 className=" text-2xl md:text-3xl font-bold">Mes repas de {currentWeekOffset === 0 ? 'la semaine' : 'la semaine prochaine'}.</h2> */}
            {/* <div className="filter flex flex-wrap gap-2 items-center">
                    <div className='flex items-center gap-2 text-orange-500 py-1 mt-4 w-fit uppercase font-semibold rounded-lg text-sm'>
                        <div className='w-3 h-3 bg-orange-500 rounded-full'></div> Calories
                    </div>
                    <div className="w-4"></div>
                    <div className='flex items-center gap-2 text-red-500 bg-red-100 px-3 py-1 mt-4 w-fit rounded-lg text-sm'>
                        <div className='w-3 h-3 bg-red-500 rounded-full'></div> Protéines
                    </div>
                    <div className='flex items-center gap-2 text-green-500 bg-green-100 px-3 py-1 mt-4 w-fit rounded-lg text-sm'>
                        <div className='w-3 h-3 bg-green-500 rounded-full'></div> Lipides
                    </div>
                    <div className='flex items-center gap-2 text-yellow-500 bg-yellow-100 px-3 py-1 mt-4 w-fit rounded-lg text-sm'>
                        <div className='w-3 h-3 bg-yellow-500 rounded-full'></div> Glucides
                    </div>
                </div> */}
            {/* </div> */}

            <div id='planning' className="relative">

                <div className='shadow-md sticky top-0 z-10 bg-white pb-1 pt-2'>
                    <div className='flex items-center gap-2 justify-between px-4 mb-2'>
                        <div className='w-10'>
                            {
                                currentWeekOffset !== 0 &&
                                <ArrowLeftIcon onClick={() => { changeWeek(0) }} className='w-5 h-5 cursor-pointer' />
                            }
                        </div>
                        <h2 className="font-semibold text-gray-800">{currentWeekOffset === 0 ? 'Semaine en cours' : 'Semaine prochaine'}</h2>
                        <div className='w-10 flex justify-end'>
                            {
                                currentWeekOffset !== 1 &&
                                <ArrowRightIcon onClick={() => { changeWeek(1) }} className='w-5 h-5 cursor-pointer' />
                            }
                        </div>
                    </div>
                    <div className="flex justify-around">
                        {
                            activeWeek.map((day, index) => {
                                const formattedDate = formatDate(day); // Example: "lundi 21 novembre"
                                const [weekday, dayNumber] = formattedDate.split(' '); // Extract weekday and day number
                                return (
                                    <div
                                        key={index}
                                        ref={(el) => (dayRefs.current[index] = el)}
                                        className={"flex flex-col items-center px-1 transition duration-200 cursor-pointer"}
                                        onClick={() => { scrollToDay(index); setCurrentWatchedDay(formattedDate) }}
                                    >
                                        <span
                                            className={(isSameDay(day, today) ? 'text-red-400 ' : '') + 'text-sm text-gray-500'}
                                        >
                                            {weekday.charAt(0).toUpperCase() + weekday.slice(1, 3)} {/* Capitalize and shorten */}
                                        </span>
                                        <span
                                            className={(currentWatchedDay === formattedDate ? ' w-6 h-6 flex items-center font-bold justify-center bg-nutrimmy-dark text-white rounded-full ' : '') + (isSameDay(day, today) ? 'text-red-500' : '')}
                                        >
                                            {dayNumber}
                                        </span>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>

                <div
                    id="planning-grid"
                    className="grid grid-cols-[repeat(7,minmax(100%,350px))] gap-x-4 overflow-x-auto cursor-grab select-none px-2 pt-2 md:px-4 pb-4 mb-20"
                    ref={gridRef}
                    onMouseDown={handleMouseDown}
                    onScroll={handleScroll}
                >
                    {/* First row: Display the days of the week */}
                    {/* <div className="none"></div> */}
                    {activeWeek.map((day, index) => (
                        <DayHeader key={index} day={(day)} index={index} dietInfo={dietInfo} mealPlanning={mealPlanning} />
                    ))}

                    {
                        mealPlanningProviderReady ?
                            MEAL_SCHEDULE[profile.meal_schedule].map((mealType) => (
                                <React.Fragment key={mealType}>
                                    {activeWeek.map((day, index) => {
                                        const dayKey = day.toISOString().split('T')[0];
                                        if (mealPlanning[dayKey][mealType as MealType]) {
                                            return <MealItem key={`${mealType}-${dayKey}`} meal={mealPlanning[dayKey][mealType as MealType]!} setMeal={(meal: Meal) => { setMealPlanning((prevMealPlanning) => ({ ...prevMealPlanning, [dayKey]: { ...prevMealPlanning[dayKey], [mealType]: meal } })) }} />;
                                        }
                                        return <div key={`${mealType}-${dayKey}`} className="text-gray-500">No meal</div>;
                                    })}
                                </React.Fragment>
                            ))
                            :
                            MEAL_SCHEDULE[profile.meal_schedule].map((mealType) => (
                                <React.Fragment key={mealType}>
                                    {activeWeek.map((day, index) => {
                                        const dayKey = day.toISOString().split('T')[0];
                                        return <div key={`${mealType}-${dayKey}`} className="text-gray-500 flex gap-2 flex-col justify-center items-center w-full h-24 rounded-lg bg-white shadow p-4">
                                            <div role="status">
                                                <svg aria-hidden="true" className="w-5 h-5 text-gray-100 animate-spin dark:text-gray-200 fill-rose-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                </svg>
                                            </div>
                                            <span className="text-sm">On récupère votre {(MEAL_TYPE_FR[mealType as MealType]).toLowerCase()}...</span>
                                        </div>
                                    })}
                                </React.Fragment>
                            ))}
                </div>
            </div>

            {
                mealPlanningProviderReady &&
                <WeekPlanGenerator dietInfo={dietInfo} profile={profile} />
            }

        </div>
    );
};

export default Planning;
