import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecipeProvider } from '../provider/RecipeProvider';
import { useNotification } from '../provider/NotificationProvider';
import { Recipe } from '../types/recipeType';
import RecipeForm from '../components/RecipeForm';
import { LoadingFullScreen } from '../components/Loading';

const UpdateRecipe: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [recipe, setRecipe] = useState<Recipe>({
        name: '',
        ingredients: [],
        type: 'MEAL',
        is_public: false,
        is_favorite: false,
        last_modified: new Date(),
    });
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { addNotification } = useNotification();
    const { getRecipeDetails, updateRecipe } = useRecipeProvider();

    useEffect(() => {
        setLoading(true);
        getRecipeDetails(Number(id))
            .then((data) => setRecipe(data!))
            .finally(() => setLoading(false));
    }, [id]);

    const handleSave = () => {
        setSaving(true);
        updateRecipe(recipe)
            .then((response) => {
                if (response === "NOT_UPDATED") {
                    addNotification('error', 'Une erreur est survenue lors de la mise à jour de la recette.');
                } else if (response === "UPDATED") {
                    addNotification('success', 'Recette mise à jour avec succès');
                    navigate('/dashboard/recipes');
                }
            })
            .finally(() => setSaving(false));
    };

    if (loading) {
        return <LoadingFullScreen text="Chargement de la recette..." />;
    }

    return (
        <RecipeForm
            recipe={recipe}
            setRecipe={setRecipe}
            onSubmit={handleSave}
            isSaving={saving}
            submitLabel="Modifier la recette"
        />
    );
};

export default UpdateRecipe;