import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecipeProvider } from '../provider/RecipeProvider';
import { useNotification } from '../provider/NotificationProvider';
import { Recipe } from '../types/recipeType';
import RecipeForm from '../components/RecipeForm';

const CreateRecipe: React.FC = () => {
    const [recipe, setRecipe] = useState<Recipe>({
        name: '',
        ingredients: [],
        type: 'MEAL',
        is_public: false,
        is_favorite: false,
        last_modified: new Date(),
    });
    const [saving, setSaving] = useState(false);
    const navigate = useNavigate();
    const { addNotification } = useNotification();
    const { createRecipe } = useRecipeProvider();

    const handleSave = () => {
        setSaving(true);
        createRecipe(recipe)
            .then((response) => {
                if (response === "NOT_CREATED") {
                    addNotification('error', 'Une erreur est survenue lors de la création de la recette.');
                } else if (response === "CREATED") {
                    addNotification('success', 'Recette créée avec succès');
                    navigate('/dashboard/recipes');
                }
            })
            .finally(() => setSaving(false));
    };

    return (
        <RecipeForm
            recipe={recipe}
            setRecipe={setRecipe}
            onSubmit={handleSave}
            isSaving={saving}
            submitLabel="Enregistrer la recette"
        />
    );
};

export default CreateRecipe;