// src/pages/Profile.tsx
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import FoodSearch from '../components/FoodSearch';
import { Ingredient, Recipe, RecipeType } from '../types/recipeType';
import { recalculateIngredientNutrimentsQuantity } from '../services/diet';
import { serializeRecipeAPI } from '../services/recipeService';
import { TrashIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../provider/NotificationProvider';
import apiClient from '../services/apiClient';
import { RadioButton } from '../components/WeekPlanGenerator';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useRecipeProvider } from '../provider/RecipeProvider';

export const IngredientCard: React.FC<{ ingredient: Ingredient, updateIngredient: (ingredient: Ingredient) => void, removeIngredient: (fdc_id: number) => void }> = ({ ingredient, updateIngredient, removeIngredient }) => {
    // const [ingredient, setIngredient] = useState(_ingredient);

    const changeQuantity = (newQuantity: number) => {
        const recalculatedIngredient = recalculateIngredientNutrimentsQuantity(ingredient, newQuantity * ingredient.portion_gram_weight);
        // setIngredient({ ...ingredient, quantity: newQuantity, nutriments: recalculatedIngredient })
        updateIngredient({
            ...ingredient,
            quantity: newQuantity * ingredient.portion_gram_weight,
            portion_quantity: newQuantity,
            nutriments: recalculatedIngredient
        });
    };

    useEffect(() => {
    }, []);

    return (
        <div
            className="bg-white py-2 px-2 md:px-4 w-full border-l-2 border-rose-300"
        >

            <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-2">
                <h5>{ingredient.name}</h5>
                <div className='flex w-fit items-center gap-2 bg-gray-50 px-1 py-1 pr-2 rounded-lg'>
                    <input
                        type="number"
                        value={Number(ingredient.portion_quantity)}
                        onChange={(e) => changeQuantity(Number(e.target.value))}
                        className="border-b border-l px-1 w-24 rounded-md"
                    />
                    {ingredient.portion_name}
                </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between items-end gap-2">
                <div className='flex flex-wrap gap-2'>
                    <div className='flex flex-col items-center text-orange-500 bg-orange-50 px-3 py-1 w-fit rounded-lg uppercase text-xs'>
                        <span className='font-bold lowercase'>{ingredient.nutriments.calories}kcal</span>
                        Calories
                    </div>
                    <div className='flex flex-col items-center text-blue-500 bg-blue-50 px-3 py-1 w-fit rounded-lg uppercase text-xs'>
                        <span className='font-bold lowercase'>{ingredient.nutriments.proteins}g</span>
                        Protéines
                    </div>
                    <div className='flex flex-col items-center text-yellow-500 bg-yellow-50 px-3 py-1 w-fit rounded-lg uppercase text-xs'>
                        <span className='font-bold lowercase'>{ingredient.nutriments.fats}g</span>
                        Lipides
                    </div>
                    <div className='flex flex-col items-center text-green-500 bg-green-50 px-3 py-1 w-fit rounded-lg uppercase text-xs'>
                        <span className='font-bold lowercase'>{ingredient.nutriments.carbohydrates}g</span>
                        Glucides
                    </div>
                </div>
                <div onClick={() => removeIngredient(ingredient.fdc_id)} className="flex h-7 items-center bg-red-100 p-2 rounded-lg w-fit gap-2 px-4 py-1 cursor-pointer transition duration-200 hover:bg-gray-200">
                    <TrashIcon className="h-4 w-4 text-red-600" />
                    {/* <span>Suprimer</span> */}
                </div>
            </div>
        </div>
    );
}

const HandleRecipe: React.FC<{ _recipe: Recipe | null, _action: 'create' | 'update' }> = ({ _recipe, _action }) => {
    const [recipe, setRecipe] = useState<Recipe>(_recipe || {
        name: "",
        ingredients: [],
        type: "MEAL",
        is_public: false,
        is_favorite: false,
        last_modified: new Date()
    });
    const [saving, setSaving] = useState(false);
    const navigate = useNavigate();
    const { addNotification } = useNotification();
    const { createRecipe, updateRecipe } = useRecipeProvider();
    const changeRecipeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRecipe({ ...recipe, name: event.target.value });
    };

    const addIngredient = (ingredient: Ingredient) => {
        setRecipe((prevRecipe) => ({
            ...prevRecipe,
            ingredients: [...prevRecipe.ingredients, ingredient],
        }));
    };

    const updateIngredient = (updatedIngredient: Ingredient) => {
        setRecipe((prevRecipe) => {
            const updatedIngredients = prevRecipe.ingredients.map((ingredient) =>
                ingredient.fdc_id === updatedIngredient.fdc_id
                    ? { ...ingredient, ...updatedIngredient } // Merge the updated ingredient data
                    : ingredient
            );
            return {
                ...prevRecipe,
                ingredients: updatedIngredients,
            };
        });
    };

    const removeIngredient = (fdc_id: number) => {
        setRecipe((prevRecipe) => ({
            ...prevRecipe,
            ingredients: prevRecipe.ingredients.filter((ingredient) => ingredient.fdc_id !== fdc_id),
        }));
    };

    const updateType = (newType: RecipeType) => {
        setRecipe({ ...recipe, type: newType });
    };

    return (
        <div className="flex flex-col gap-4 md:p-8 bg-nutrimmy-bg">
            <div onClick={() => navigate(`/dashboard/recipes/`)} className="flex h-7 items-center bg-gray-100 p-2 rounded-lg w-fit gap-2 px-4 py-1 cursor-pointer transition duration-200 hover:bg-gray-200">
                <ArrowLeftIcon className="h-4 w-4 text-gray-600" />
                <span>Quitter</span>
            </div>
            <div className='flex flex-col-reverse md:flex-row gap-8'>
                <div className='md:w-2/5'>
                    <FoodSearch addIngredient={addIngredient} />
                </div>
                <div className='md:w-3/5 border-gray-200 md:min-h-[500px]'>
                    <div className="">

                        <input
                            type="text"
                            placeholder="Nom de la recette"
                            value={recipe.name}
                            onChange={changeRecipeName}
                            className="font-bold border border-rose-200 rounded-lg py-2 px-4 w-full"
                        />
                        {/* <select name="type" id="" className="md:border-b md:border-l w-48 py-2 px-4 mb-4" onChange={updateType} defaultValue={recipe.type}>
                        <option value="BREAKFAST">Petit dejeuner</option>
                        <option value="MEAL">Repas</option>
                        <option value="SNACK">Collation</option>
                        <option value="DESSERT">Dessert</option>
                        <option value="OTHER">Autre</option>
                    </select> */}
                        <RadioButton radioDisplay={['Petit dejeuner', 'Repas', 'Collation']} radioValues={['BREAKFAST', 'MEAL', 'SNACK']} selectedValue={recipe.type} setSelectedValue={updateType} />
                    </div>
                    {
                        <div className='flex flex-col gap-2 my-4'>
                            {recipe.ingredients.map((ingredient, index) => (
                                <IngredientCard
                                    key={ingredient.fdc_id}
                                    ingredient={ingredient}
                                    updateIngredient={updateIngredient}
                                    removeIngredient={removeIngredient}
                                />
                            ))}
                            {
                                recipe.ingredients.length === 0 &&
                                <p className='text-orange-500'>Vous devez ajouter au moins un ingrédient.</p>
                            }
                        </div>
                    }
                    {
                        recipe.ingredients.length > 0 && _action === 'create' &&
                        <div
                            className={`bg-green-700 cursor-pointer transition duration-200 hover:bg-green-600 text-white font-bold py-2 px-8 rounded-lg w-fit ${saving ? 'opacity-50' : ''}`}
                            onClick={() => {setSaving(true); createRecipe(recipe).finally(() => setSaving(false))}}
                        >
                            {saving ? "Enregistrement de la recette..." : "Enregistrer la recette"}
                        </div>
                    }
                    {
                        recipe.ingredients.length > 0 && _action === 'update' &&
                        <div
                            className={`bg-green-700 cursor-pointer transition duration-200 hover:bg-green-600 text-white font-bold py-2 px-8 rounded-lg w-fit ${saving ? 'opacity-50' : ''}`}
                            onClick={() => {setSaving(true); updateRecipe(recipe).finally(() => setSaving(false))}}
                        >
                            {saving ? "Modification de la recette..." : "Modifier la recette"}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default HandleRecipe;