export function calculateAge(date: string) {
	const today = new Date();
	const birthDate = new Date(date);

	let age = today.getFullYear() - birthDate.getFullYear();
	const monthDiff = today.getMonth() - birthDate.getMonth();

	if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}

	return age;
}

export function roundToClosestMultipleOf5(number: number) {
    return Math.round(number / 5) * 5;
}

export const round = (number: number, precision: number) => {
	return Math.round(number * precision) / precision;
}

export const getCurrentWeekDays = () => {
    const daysArray = [];
    const today = new Date();

    // Get the current day of the week (0 for Sunday, 1 for Monday, etc.)
    const currentDay = today.getDay();

    // Calculate how many days we need to subtract to get the previous Monday
    const daysToMonday = (currentDay === 0 ? 6 : currentDay - 1);

    // Set the date to the previous Monday
    const monday = new Date(today);
    monday.setDate(today.getDate() - daysToMonday);

    // Push 7 days from Monday (Monday to Sunday) into the array
    for (let i = 0; i < 7; i++) {
        const newDay = new Date(monday);
        newDay.setDate(monday.getDate() + i);
        daysArray.push(newDay);
    }

    return daysArray;
};

export const getWeekDays = (week_n = 0) => {
    const daysArray = [];
    const today = new Date();

    // Get the current day of the week (0 for Sunday, 1 for Monday, etc.)
    const currentDay = today.getDay();

    // Calculate how many days we need to subtract to get the previous Monday
    const daysToMonday = (currentDay === 0 ? 6 : currentDay - 1);

    // Set the date to the Monday of the current week and then adjust for week_n
    const monday = new Date(today);
    monday.setDate(today.getDate() - daysToMonday + week_n * 7);

    // Push 7 days from the calculated Monday (Monday to Sunday) into the array
    for (let i = 0; i < 7; i++) {
        const newDay = new Date(monday);
        newDay.setDate(monday.getDate() + i);
        daysArray.push(newDay);
    }

    return daysArray;
};

export const createWeekArray = (dateStart: Date, numberOfWeeks: number) => {
    const weekArray = [];
    for (let i = 0; i < numberOfWeeks; i++) {
        weekArray.push(new Date(dateStart));
        dateStart.setDate(dateStart.getDate() + 7);
    }
    return weekArray;
}


export const getNext7Days = () => {
    const daysArray = [];
    const today = new Date();
    for (let i = 0; i < 7; i++) {
        const newDay = new Date(today);
        newDay.setDate(today.getDate() + i);
        daysArray.push(newDay);
    }
    return daysArray;
};

export const formatDate = (date: Date) => {
	return date.toLocaleDateString('fr-FR', {
		weekday: 'long',
		day: 'numeric',
		month: 'long',
	});
};




