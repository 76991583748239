import React, { useState } from 'react';
import { useUserProvider } from '../provider/UserProvider';
import CustomGoogleLogin from '../components/CustomGoogleLogin';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // Import Yup for validation
import { useNavigate } from 'react-router-dom';
import InAppHandle from './InAppHandle';
import { LoadingFullScreen } from '../components/Loading';

export const Login: React.FC = () => {
    const [isConnecting, setIsConnecting] = useState(false);

    const { handleEmailLogin } = useUserProvider();
    const navigate = useNavigate();

    // const isInAppBrowser = false;
    const isInAppBrowser = /instagram|facebook/.test(navigator.userAgent.toLowerCase());

    // Define the advanced validation schema using Yup
    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Adresse email invalide') // Validate email format
            .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                'Veuillez entrer une adresse email valide'
            )
            .required('L\'email est requis'), // Ensure email is required
        password: Yup.string()
            .min(8, 'Le mot de passe doit contenir au moins 8 caractères') // Minimum length
            .matches(/[a-z]/, 'Le mot de passe doit contenir au moins une lettre minuscule') // At least one lowercase letter
            .matches(/[A-Z]/, 'Le mot de passe doit contenir au moins une lettre majuscule') // At least one uppercase letter
            .matches(/[0-9]/, 'Le mot de passe doit contenir au moins un chiffre') // At least one digit
            .matches(/[@$!%*?&]/, 'Le mot de passe doit contenir au moins un caractère spécial (@, $, !, %, *, ?, &)') // At least one special character
            .required('Le mot de passe est requis'), // Ensure password is required
    });

    const formik = useFormik({
        initialValues: { email: '', password: '' },
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            setIsConnecting(true);
            handleEmailLogin(values.email, values.password, setSubmitting).finally(() => setIsConnecting(false));
        },
    });

    if (isConnecting) {
        return <LoadingFullScreen text="Connexion en cours..." />
    }

    return (
        <div className='bg-nutrimmy-bg min-h-screen flex flex-col items-center justify-center p-2'>
            <div
                className="absolute top-2 left-2 flex items-center gap-2 cursor-pointer text-gray-800 transition duration-200 hover:text-gray-800"
                onClick={() => navigate('/')}
            >
                <ChevronLeftIcon className="h-4 w-4" />
                Retour
            </div>

            <h1 className='text-2xl md:text-3xl font-bold text-gray-800 mb-8 md:max-w-[400px]'>
                Connectez-vous et accédez à votre planning.
            </h1>

            <form onSubmit={formik.handleSubmit} className="form flex flex-col w-full md:w-[400px] mb-4">
                <input
                    type="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} // Handle blur event for validation
                    value={formik.values.email}
                    placeholder='Entrez votre email'
                    className={`mb-2 p-2 border-b ${formik.errors.email && formik.touched.email ? 'border-red-500' : 'border-rose-300'}`}
                />
                {formik.errors.email && formik.touched.email && (
                    <p className="text-red-500 text-sm mb-2">{formik.errors.email}</p>
                )}

                <input
                    type="password"
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} // Handle blur event for validation
                    value={formik.values.password}
                    placeholder='Créez votre mot de passe'
                    className={`mb-2 p-2 border-b ${formik.errors.password && formik.touched.password ? 'border-red-500' : 'border-rose-300'}`}
                />
                {formik.errors.password && formik.touched.password && (
                    <p className="text-red-500 text-sm mb-2">{formik.errors.password}</p>
                )}

                <button
                    type="submit"
                    className="bg-rose-500 border-rose-500 border-2 cursor-pointer text-white font-bold py-2 px-4 rounded hover:bg-nutrimmy-bg hover:text-rose-500 transition duration-200"
                    disabled={!formik.isValid || formik.isSubmitting} // Disable button if form is invalid
                >
                    Se connecter / S'inscrire
                </button>
            </form>

            <div className='h-[1px] w-full bg-gray-200 my-6 mt-4'></div>
            {
                isInAppBrowser ? (
                    <p className='text-gray-500 text-sm'>
                        Pour vous connecter avec Google, merci d'ouvrir Nutrimmy dans votre navigateur préféré en <b>cliquant sur les 3 points en haut à droite</b>.
                    </p>
                ) : (
                    < div className="google md:w-[400px] mb-8 flex flex-col items-center gap-1">
                        <p className='text-gray-600'>Ou alors, connexion/inscription rapide avec</p>
                        <CustomGoogleLogin text="signin_with" type="icon" />
                    </div>
                )
            }


        </div >
    );
};

export default Login;