import React, { useEffect, useState } from 'react';
import { Recipe, RECIPE_TYPE_BREAKFAST, RECIPE_TYPE_MEAL, RECIPE_TYPE_SNACK, RecipeType } from '../types/recipeType';
import Cookies from 'js-cookie';
import { getUserRecipes, getUserRecipesDetailed } from '../services/recipeService';
import { MEAL_SCHEDULE, optimizeMeal, recipeToMeal } from '../services/mealService';
import { useNotification } from '../provider/NotificationProvider';
import { Meal, MealType } from '../types/mealType';
import apiClient from '../services/apiClient';
import { createMealPlanning, UserDietInfo } from '../services/diet';
import { LoadingFullScreen } from './Loading';
import { UserProfile } from '../pages/Profile';
import { useMealPlanning } from '../provider/MealPlanningProvider';



export const RadioButton: React.FC<{ radioDisplay: string[], radioValues: any[], selectedValue: any, setSelectedValue: React.Dispatch<React.SetStateAction<any>> }> = ({ radioDisplay, radioValues, selectedValue, setSelectedValue }) => {    
    return (
        <div className="flex flex-wrap items-center gap-2 py-2">
            {radioValues.map((value, index) => (
                <div
                    onClick={() => { selectedValue !== value && setSelectedValue(value) }}
                    key={value}
                    className={`w-fit rounded-lg px-4 py-1 flex items-center justify-center transition duration-200 ${selectedValue === value ? 'bg-rose-500 text-white' : 'bg-gray-100  cursor-pointer'}`}
                >
                    <span className=""> {radioDisplay[index]} </span>
                </div>
            ))}
        </div>
    );
};


const WeekPlanGenerator: React.FC<{
    dietInfo: UserDietInfo,
    profile: UserProfile
}> = ({ dietInfo, profile }) => {
    const MY_RECIPE_ONLY = 'MY_RECIPE_ONLY';
    const FAVORITE_RECIPE_ONLY = 'FAVORITE_RECIPE_ONLY';
    const ALL_RECIPES = 'ALL_RECIPES';
    const CHOOSEN_RECIPE = 'CHOOSEN_RECIPE';

    const [breakfastRotation, setBreakfastRotation] = useState<number>(2);
    // const [mealRotation, setMealRotation] = useState<number>(4);
    const [lunchRotation, setLunchRotation] = useState<number>(3);
    const [dinnerRotation, setDinnerRotation] = useState<number>(3);
    const [snackRotation, setSnackRotation] = useState<number>(2);
    const [recipePreference, setRecipePreference] = useState<string>(FAVORITE_RECIPE_ONLY);
    const [selectedRecipes, setSelectedRecipes] = useState<number[]>([]); // all ids of selected recipes
    const [optimizeRecipe, setOptimizeRecipe] = useState<boolean>(true);

    const [recipeList, setRecipeList] = useState<Recipe[]>([]);

    const [loaded, setLoaded] = useState<boolean>(true);
    const [generatingWeek, setGeneratingWeek] = useState<boolean>(false);
    const [savingWeek, setSavingWeek] = useState<boolean>(false);
    const [readyToSave, setReadyToSave] = useState<boolean>(false);
    const { addNotification } = useNotification();
    const { activeWeek, mealPlanning, setMealPlanning, updateMealPlanning } = useMealPlanning();

    const token = Cookies.get('authToken_access') as string;

    // Helper to randomly shuffle an array
    const shuffleArray = (array: Recipe[]): Recipe[] => {
        return array
            .map(a => [a, Math.random()] as [Recipe, number])
            .sort((a, b) => a[1] - b[1])
            .map(a => a[0]);
    };

    const getQuickRecipe = async () => {
        try {
            const fetchedRecipes = await getUserRecipes({ token });
            setRecipeList(fetchedRecipes as Recipe[]);
        } catch (error) {
            console.log(error);
            return [];
        }
    };

    const generateWeekPlanning = async () => {
        setLoaded(false);
        setGeneratingWeek(true);

        // Helper function: Calculate recipe distribution across a given number of days
        const calculateRecipeDistribution = (totalDays: number, recipes: Recipe[]): Recipe[] => {
            const recipeDistribution: Recipe[][] = [];
            const baseCount = Math.floor(totalDays / recipes.length);
            const extraCount = totalDays % recipes.length;

            recipes.forEach((recipe, index) => {
                const days = Array(baseCount + (index < extraCount ? 1 : 0)).fill(recipe);
                recipeDistribution.push(days);
            });

            return recipeDistribution.flat();
        };

        // Helper function: Add notification for insufficient recipes
        const addInsufficientRecipeNotification = (type: string, available: number, required: number) => {
            if (available < required) {
                addNotification(
                    'warning',
                    `Il manque des ${type} pour assurer la rotation de la semaine. Vous avez sélectionné ${required} ${type}, mais seulement ${available} sont disponibles.`,
                    10000
                );
            }
        };

        try {
            const _recipes = await getUserRecipesDetailed({ token });
            let recipes: Recipe[] = _recipes as Recipe[];

            // Filter recipes based on user preference
            if (recipePreference === FAVORITE_RECIPE_ONLY) {
                recipes = recipes.filter(r => r.is_favorite);
            } else if (recipePreference === MY_RECIPE_ONLY) {
                recipes = recipes.filter(r => !r.is_public);
            } else if (recipePreference === CHOOSEN_RECIPE) {
                recipes = recipes.filter(r => selectedRecipes.includes(r.id!));
            }

            // Group recipes by RecipeType
            const groupedRecipes: { [key in RecipeType]: Recipe[] } = {
                BREAKFAST: shuffleArray(recipes.filter(r => r.type === RECIPE_TYPE_BREAKFAST)),
                MEAL: shuffleArray(recipes.filter(r => r.type === RECIPE_TYPE_MEAL)),
                SNACK: shuffleArray(recipes.filter(r => r.type === RECIPE_TYPE_SNACK)),
                DESSERT: [],
                OTHER: []
            };

            // Check if minimum recipes for rotation exist
            if (groupedRecipes.BREAKFAST.length === 0 || groupedRecipes.MEAL.length === 0 || groupedRecipes.SNACK.length === 0) {
                addNotification(
                    'error',
                    `Vous n'avez pas assez de recettes disponibles pour remplir le planning de la semaine. Ajoutez au moins ${breakfastRotation} petits déjeuners, ${lunchRotation} déjeuners, ${dinnerRotation} dîners et ${snackRotation} snacks ou modifiez la rotation.`,
                    10000
                );
                setLoaded(true);
                return;
            }

            // Notifications for insufficient recipes
            addInsufficientRecipeNotification('petits déjeuners', groupedRecipes.BREAKFAST.length, breakfastRotation);
            addInsufficientRecipeNotification('déjeuners', groupedRecipes.MEAL.length - dinnerRotation, lunchRotation);
            addInsufficientRecipeNotification('dîners', groupedRecipes.MEAL.length - lunchRotation, dinnerRotation);
            addInsufficientRecipeNotification('snacks', groupedRecipes.SNACK.length, snackRotation);

            const newMealPlanning: { [day: string]: { [key in MealType]?: Meal } } = createMealPlanning(dietInfo, Number(profile.meal_schedule), activeWeek);

            // Split MEAL recipes for LUNCH and DINNER rotations
            // const halfMealRecipes = Math.ceil(groupedRecipes.MEAL.length / 2);
            const lunchRecipes = groupedRecipes.MEAL.slice(0, lunchRotation);
            const dinnerRecipes = groupedRecipes.MEAL.slice(lunchRotation, dinnerRotation + lunchRotation);
            // console.log('groupedRecipes', groupedRecipes);
            // console.log('lunchRecipes', lunchRecipes);
            // console.log('dinnerRecipes', dinnerRecipes);

            // Predefine rotations for each meal category
            const breakfastRecipeRotation = calculateRecipeDistribution(7, groupedRecipes.BREAKFAST.slice(0, breakfastRotation));
            const lunchRecipeRotation = calculateRecipeDistribution(7, lunchRecipes);
            const dinnerRecipeRotation = calculateRecipeDistribution(7, dinnerRecipes);
            const snackRecipeRotation = calculateRecipeDistribution(7, groupedRecipes.SNACK.slice(0, snackRotation));

            const mealSchedule = MEAL_SCHEDULE[profile.meal_schedule];

            // Generate the weekly plan by day and meal type
            Object.keys(newMealPlanning).forEach((day, index) => {
                const dayIndex = index;

                mealSchedule.forEach(mealType => {
                    let selectedRecipe: Recipe;

                    // Assign recipes based on meal type
                    switch (mealType) {
                        case 'BREAKFAST':
                            selectedRecipe = breakfastRecipeRotation[dayIndex];
                            break;
                        case 'LUNCH':
                            selectedRecipe = lunchRecipeRotation[dayIndex];
                            break;
                        case 'DINNER':
                            selectedRecipe = dinnerRecipeRotation[dayIndex];
                            break;
                        case 'SNACK_1':
                            selectedRecipe = snackRecipeRotation[dayIndex % snackRecipeRotation.length];
                            break;
                        case 'SNACK_2':
                            selectedRecipe = snackRecipeRotation[(dayIndex + 1) % snackRecipeRotation.length];
                            break;
                        case 'SNACK_3':
                            selectedRecipe = snackRecipeRotation[(dayIndex + 2) % snackRecipeRotation.length];
                            break;
                        default:
                            throw new Error(`Type de repas inconnu : ${mealType}`);
                    }

                    // Optimize or assign the meal
                    if (optimizeRecipe) {
                        newMealPlanning[day][mealType] = optimizeMeal(recipeToMeal(selectedRecipe, newMealPlanning[day][mealType]!, true));
                    } else {
                        newMealPlanning[day][mealType] = recipeToMeal(selectedRecipe, newMealPlanning[day][mealType]!);
                    }
                });
            });

            setMealPlanning(newMealPlanning);
            console.log("Generated meal plan:", newMealPlanning);

        } catch (error) {
            console.log(error);
            addNotification('error', `Une erreur est survenue lors de la génération du planning : ${error}`, 10000);
        }

        setLoaded(true);
        setGeneratingWeek(false);
        setReadyToSave(true);
    };

    const saveGeneratedMealPlan = async () => {
        setReadyToSave(false);
        setSavingWeek(true);
        apiClient.post('/api/meal/week-generate', mealPlanning,
            {
                headers: { Authorization: `Bearer ${token}` }
            }).then((response) => {
                let updatedMealPlanning: { [day: string]: { [key in MealType]?: Meal } } = mealPlanning;
                response.data.forEach((meal: Meal) => {
                    const day: string = meal.date.toString();
                    const mealType: MealType = meal.type;
                    updatedMealPlanning[day][mealType] = {
                        ...updatedMealPlanning[day][mealType]!,
                        id: meal.id
                    };
                });
                updateMealPlanning(updatedMealPlanning);
                addNotification('success', 'Planning généré avec succès !');
            }).catch((error) => {
                console.error(error);
                addNotification('error', 'Une erreur est survenue lors de la génération du planning : ' + error);
            })
            .finally(() => {
                setSavingWeek(false);
            });
    }

    useEffect(() => {
        setLoaded(false);
        getQuickRecipe().finally(() => {
            setLoaded(true);
        })
    }, []);

    return (
        <div>

            {
                readyToSave && (
                    <div className='fixed top-0 bg-white left-1/2 transform -translate-x-1/2 w-full md:w-max flex flex-col md:flex-row items-center justify-center gap-2 md:gap-4 p-4 rounded-b-lg shadow-2xl'>
                        <p>Votre planning a bien été géneré, que voulez vous faire?</p>
                        <div className='bg-green-600 text-white px-4 py-2 rounded-lg cursor-pointer transition duration-200 hover:bg-green-600' onClick={saveGeneratedMealPlan}>
                            Sauvegarder mon planning
                        </div>
                        <div className='bg-red-600 text-white px-4 py-2 rounded-lg cursor-pointer transition duration-200 hover:bg-red-600' onClick={() => window.location.reload()}>
                            Annuler
                        </div>
                    </div>
                )
            }

            {generatingWeek && <LoadingFullScreen text="Création du planning en cours..." />}
            {savingWeek && <LoadingFullScreen text="Sauvegarde de la semaine en cours..." />}


            <div className='flex flex-col gap-4 p-2 md:p-8 py-16 bg-gray-50'>
                <div>
                    <h4 className="text-xl md:text-3xl font-bold">Générer ma semaine de repas en quelques clics.</h4>
                    {/* <p className='text-sm text-gray-500 italic'>Cliquez sur le bouton ci-dessous pour générer votre planning pour toute la semaine.</p> */}
                    {/* <p className='text-sm text-orange-400 italic'>Attention si vous avez deja des repas dans le planning ils seront remplacés une fois le planning confirmé.</p> */}
                </div>

                <div className='w-fit bg-white p-2 md:p-4 rounded-lg border border-gray-100'>
                    <h5 className='font-bold'>Paramètres des petits dejeuners</h5>
                    <h6 className=''>Rotation des petits dejeuners</h6>
                    <p className='text-sm text-gray-500 italic'>Nombre de petits dejeuners differents dans la semaine. Dans ce cas, vous aurez {breakfastRotation} recettes de petits dejeuners differentes dans la semaine.</p>
                    <RadioButton radioDisplay={['1 recette', '2 recettes', '3 recettes', '4 recettes', '5 recettes', '6 recettes', '7 recettes']} radioValues={[1, 2, 3, 4, 5, 6, 7]} selectedValue={breakfastRotation} setSelectedValue={setBreakfastRotation} />
                </div>

                <div className='w-fit bg-white p-2 md:p-4 rounded-lg border border-gray-100'>
                    <h5 className='font-bold'>Paramètres des repas</h5>
                    <h6 className=''>Rotation des recettes du déjeuner</h6>
                    <p className='text-sm text-gray-500 italic'>Nombre de déjeuners differents dans la semaine. Dans ce cas, vous aurez {lunchRotation} recettes de dejeuners differentes dans la semaine.</p>
                    <RadioButton radioDisplay={['1 recette', '2 recettes', '3 recettes', '4 recettes', '5 recettes', '6 recettes', '7 recettes']} radioValues={[1, 2, 3, 4, 5, 6, 7]} selectedValue={lunchRotation} setSelectedValue={setLunchRotation} />
                    <h6 className='mt-2'>Rotation des recettes du dîner</h6>
                    <p className='text-sm text-gray-500 italic'>Nombre de dîners differents dans la semaine. Dans ce cas, vous aurez {dinnerRotation} recettes de dîner differentes dans la semaine.</p>
                    <RadioButton radioDisplay={['1 recette', '2 recettes', '3 recettes', '4 recettes', '5 recettes', '6 recettes', '7 recettes']} radioValues={[1, 2, 3, 4, 5, 6, 7]} selectedValue={dinnerRotation} setSelectedValue={setDinnerRotation} />
                </div>

                <div className='w-fit bg-white p-2 md:p-4 rounded-lg border border-gray-100'>
                    <h5 className='font-bold'>Paramètres des collations</h5>
                    <h6 className=''>Rotation des collations</h6>
                    <p className='text-sm text-gray-500 italic'>Nombre de collations differentes dans la semaine. Dans ce cas, vous aurez {snackRotation} recettes de collations differentes dans la semaine.</p>
                    <RadioButton radioDisplay={['1 recette', '2 recettes', '3 recettes', '4 recettes', '5 recettes', '6 recettes', '7 recettes']} radioValues={[1, 2, 3, 4, 5, 6, 7]} selectedValue={snackRotation} setSelectedValue={setSnackRotation} />
                </div>

                <div className='w-fit bg-white p-2 md:p-4 rounded-lg border border-gray-100'>
                    <h5 className='font-bold'>Paramètres des recettes</h5>
                    <p className='text-sm text-gray-500 italic'><span className='font-semibold'>Utiliser mes recettes</span> - L'algorithme choisira aléatoirement des recettes faites par vous.</p>
                    <p className='text-sm text-gray-500 italic'><span className='font-semibold'>Utiliser mes recettes favorites</span> - L'algorithme choisira aléatoirement des recettes que vous avez mise en favoris.</p>
                    <p className='text-sm text-gray-500 italic'><span className='font-semibold'>Utiliser mes recettes et les recettes publiques</span> - L'algorithme choisira aléatoirement des recettes faites par vous et des recettes publiques.</p>
                    <p className='text-sm text-gray-500 italic'><span className='font-semibold'>Choisir mes recettes</span> - Vous permet de choisir manuellement des recettes pour cette semaine, l'algorithme utilisera ces recettes pour générer la semaine.</p>
                    <RadioButton radioDisplay={['Utiliser mes recettes', 'Utiliser mes recettes favorites', 'Utiliser mes recettes et les recettes publiques']} radioValues={[MY_RECIPE_ONLY, FAVORITE_RECIPE_ONLY, ALL_RECIPES]} selectedValue={recipePreference} setSelectedValue={setRecipePreference} />
                    {
                        recipePreference === CHOOSEN_RECIPE && loaded && (
                            <div className="p-2">
                                <h5 className='font-bold'>Choisir mes recettes</h5>
                                <p className='text-sm text-gray-500 italic'>Choisissez les recettes que vous souhaitez utiliser pour cette semaine.</p>
                                <div className="flex flex-wrap gap-2">
                                    {recipeList.map((recipe, index) => (
                                        <div key={index} className="w-fit rounded-full px-4 py-1 flex items-center justify-center border cursor-pointer transition duration-200">
                                            <span className="">{recipe.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    }

                </div>

                <div className='w-fit bg-white p-2 md:p-4 rounded-lg border border-gray-100'>
                    <h5 className='font-bold'>Optimisateur de recette</h5>
                    <p className='text-sm text-gray-500 italic'><span className='uppercase text-green-500'>Conseillé</span> - Defini automatiquement les quantités d'ingredients dans les differentes recettes pour parfaitement calibrer vos repas selon vos macros.</p>
                    <RadioButton radioDisplay={['Optimiser mes recettes', 'Ne pas optimiser mes recettes']} radioValues={[true, false]} selectedValue={optimizeRecipe} setSelectedValue={setOptimizeRecipe} />
                </div>

                {
                    loaded && (
                        <div className="bg-gray-900 cursor-pointer transition duration-200 hover:bg-gray-800 text-white font-semibold py-4 px-8 rounded-lg w-fit" onClick={generateWeekPlanning} >
                            Générer ma semaine de repas
                        </div>
                    )
                }
            </div>

        </div>
    );
};

export default WeekPlanGenerator;
