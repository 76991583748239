import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Create an Axios instance with the base URL set from environment variables
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Base URL for all requests
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // if you need to send cookies or authentication tokens
});

// Add a response interceptor
// apiClient.interceptors.response.use(
//   (response) => response, // Let successful responses pass through
//   (error) => {
//     // Check if the error is a 401 Unauthorized
//     if (error.response && error.response.status === 401) {
//       // Optional: Log the user out or clear tokens if necessary
//       localStorage.removeItem('authToken_access');
//       localStorage.removeItem('authToken_refresh');
      
//       // Redirect to dashboard or login page
//       window.location.href = '/login'; // Or use React Router's navigate hook in a component
//     }

//     // Return the error so it can be handled locally if needed
//     return Promise.reject(error);
//   }
// );

export default apiClient;