// src/pages/Landing.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { RectangleGroupIcon, UserIcon, CogIcon, ClipboardDocumentListIcon, CalendarDateRangeIcon, ArrowRightIcon } from '@heroicons/react/24/solid'
import apiClient from '../services/apiClient';
import { CheckCircleIcon, ShoppingCartIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';

const Landing: React.FC = () => {
    const navigate = useNavigate();
    const [token, setToken] = useState<string | undefined>(Cookies.get('authToken_access') || undefined);

    useEffect(() => {
        if (token) {
            apiClient.get('/api/profile/', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }).catch((error) => {
                Cookies.remove('authToken_access');
                Cookies.remove('authToken_refresh');
                setToken(undefined);
            });
        }
    });

    return (
        <div className="bg-nutrimmy-bg">
            {/* Hero Section */}

                    {/* Top Right Auth Button */}
                    <div className="fixed top-2 right-2 md:top-4 md:right-4 z-20">
                        <div
                            className="bg-nutrimmy-dark shadow-lg cursor-pointer transition duration-200 hover:bg-nutrimmy-bg hover:text-nutrimmy-dark border-nutrimmy-dark border-2 text-white py-1 md:py-2 px-6 md:px-8 rounded-lg"
                            onClick={() => navigate('/login')}
                        >
                            Se connecter / S'inscrire
                        </div>
                    </div>
            <div className='relative'>

                <div className='w-48 h-48 rounded-full bg-rose-600 absolute top-0 left-8'></div>
                <div className='w-48 h-48 rounded-full bg-rose-400 absolute bottom-2 right-8'></div>

                <section id="hero" className="w-full h-screen flex flex-col md:flex-row items-center justify-center backdrop-blur-3xl bg-nutrimmy-bg/40">

                    {/* Image for Large Screens */}
                    <div className="hidden md:block w-2/5 h-screen p-8 pr-0">
                        <img
                            src={process.env.PUBLIC_URL + '/food_bg_home.jpg'}
                            alt="Meal prep"
                            className="w-full h-full rounded-2xl object-cover object-right"
                        />
                    </div>

                    {/* Text Content */}
                    <div className="h-screen w-full md:w-3/5 flex flex-col items-center md:items-start justify-center md:pl-8 space-y-4 md:space-y-6">

                        {/* Logo */}
                        <div className="flex flex-col md:flex-row items-center md:gap-2 text-2xl md:text-3xl text-rose-500 font-bold lowercase mb-4">
                            <img
                                src={process.env.PUBLIC_URL + '/logo-full-bg-empty.svg'}
                                alt="Logo Nutrimmy"
                                className="w-6 h-6"
                            />
                            Nutrimmy
                        </div>

                        {/* Main Heading */}
                        <h1 className="text-2xl md:text-5xl text-gray-900 font-bold text-center md:text-left max-w-[90%] md:max-w-[800px]">
                            Des repas personnalisés et optimisés, juste pour vous.
                        </h1>

                        {/* Subheading */}
                        <p className="text-lg md:text-xl text-gray-800 text-center md:text-left max-w-[90%] md:max-w-[650px]">
                            Planifiez toute votre semaine, avec des repas calibrés pour vos objectifs, accessibles en un seul clic.
                        </p>

                        {/* Beta Note */}
                        <p className="text-sm md:text-md text-gray-700 italic text-center md:text-left max-w-[90%] md:max-w-[650px]">
                            Nutrimmy est actuellement en version bêta – soyez parmi les premiers à tester et profiter gratuitement de ses fonctionnalités exclusives.
                        </p>

                        {/* Call-to-Action Button */}
                        <a
                            href="/login"
                            className="flex flex-col-reverse md:flex-row items-center bg-rose-500 text-white font-bold py-3 px-4 md:px-6 rounded-lg text-center md:text-left transition duration-200 hover:bg-rose-600"
                        >
                            <ArrowRightIcon className="h-5 w-5 hidden md:block md:mr-3" />
                            Créer mon planning en quelques clics
                        </a>
                    </div>
                </section>
            </div>

            {/* Discover Section */}
            <section id="discover" className="py-16 md:py-32">
                <div className="container mx-auto px-2 md:px-0">
                    <h2 className="text-2xl md:text-4xl font-bold mb-12 md:mb-16 text-center text-gray-900">
                        Découvrez comment Nutrimmy transforme votre alimentation.
                    </h2>
                    <div className="grid gap-2 md:gap-8 md:grid-cols-3 md:gap-12 text-center">
                        <div className="p-6 md:p-8 rounded-lg bg-white border border-rose-100">
                            <CheckCircleIcon className="h-10 md:h-12 w-10 md:w-12 text-rose-600 mx-auto mb-4" />
                            <h3 className="text-lg md:text-xl font-semibold mb-2">Optimisation intelligente des recettes</h3>
                            <p className="text-gray-700">Transformez n'importe quelle recette pour qu'elle corresponde à vos besoins caloriques sans perdre les proportions d'ingrédients d'origine.</p>
                        </div>
                        <div className="p-6 md:p-8 rounded-lg bg-white border border-rose-100">
                            <ClipboardDocumentCheckIcon className="h-10 md:h-12 w-10 md:w-12 text-rose-600 mx-auto mb-4" />
                            <h3 className="text-lg md:text-xl font-semibold mb-2">Planification de repas personnalisée</h3>
                            <p className="text-gray-700">Créez des plannings de repas sur mesure en fonction de vos objectifs, pour une semaine de repas équilibrés et adaptés.</p>
                        </div>
                        <div className="p-6 md:p-8 rounded-lg bg-white border border-rose-100">
                            <ShoppingCartIcon className="h-10 md:h-12 w-10 md:w-12 text-rose-600 mx-auto mb-4" />
                            <h3 className="text-lg md:text-xl font-semibold mb-2">Liste de courses automatisée</h3>
                            <p className="text-gray-700">Générez automatiquement une liste de courses pour tous les ingrédients nécessaires à vos repas de la semaine.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Steps Section */}
            <section className="bg-white mx-auto px-2 md:px-6 py-16 md:py-32 text-center">
                <h2 className="text-2xl md:text-4xl font-semibold mb-16 md:mb-24">Comment obtenir son planning sur mesure en 2 minutes?</h2>
                <div className="space-y-8 md:space-y-12 max-w-4xl mx-auto">

                    {/* Each Step */}
                    <div className="flex flex-col md:flex-row items-center md:items-start bg-white pb-10 md:pb-12 border-b-2 text-left">
                        <div className="w-16 md:w-48 flex flex-row items-center mb-4 md:mb-0">
                            <UserIcon className="text-rose-600 mx-auto w-12 md:w-24" />
                        </div>
                        <div className="w-full md:w-4/5">
                            <h3 className="md:text-2xl font-semibold mb-2 md:mb-4">Étape 1 : Créez votre compte et profil nutritionnel</h3>
                            <p className="text-gray-700">Inscrivez-vous et entrez des informations essentielles comme votre poids, taille, et objectifs. Cela permettra à Nutrimmy de personnaliser vos repas selon vos besoins.</p>
                        </div>
                    </div>

                    {/* Additional Steps - Repeat Structure */}
                    <div className="flex flex-col md:flex-row items-center md:items-start bg-white pb-10 md:pb-12 border-b-2 text-left">
                        <div className="w-16 md:w-48 flex flex-row items-center mb-4 md:mb-0">
                            <RectangleGroupIcon className="text-rose-600 mx-auto w-12 md:w-24" />
                        </div>
                        <div className="w-full md:w-4/5">
                            <h3 className="md:text-2xl font-semibold mb-2 md:mb-4">Étape 2 : Accédez à votre Dashboard</h3>
                            <p className="text-gray-700">Dès votre connexion, vous arrivez sur votre Dashboard. Accédez à votre profil pour modifier vos informations, définir le nombre de repas par jour, accéder à votre liste de courses et à vos recettes.</p>
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row items-center md:items-start bg-white pb-10 md:pb-12 border-b-2 text-left">
                        <div className="w-16 md:w-48 flex flex-row items-center mb-4 md:mb-0">
                            <ClipboardDocumentListIcon className="text-rose-600 mx-auto w-12 md:w-24" />
                        </div>
                        <div className="w-full md:w-4/5">
                            <h3 className="md:text-2xl font-semibold mb-2 md:mb-4">Étape 3 : Ajouter des recettes</h3>
                            <p className="text-gray-700">Explorez des recettes publiques ou créez vos propres recettes. Ajoutez vos favorites pour les retrouver facilement dans vos plannings.</p>
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row items-center md:items-start bg-white pb-10 md:pb-12 border-b-2 text-left">
                        <div className="w-16 md:w-48 flex flex-row items-center mb-4 md:mb-0">
                            <CogIcon className="text-rose-600 mx-auto w-12 md:w-24" />
                        </div>
                        <div className="w-full md:w-4/5">
                            <h3 className="md:text-2xl font-semibold mb-2 md:mb-4">Étape 4 : Optimiser et générer la semaine</h3>
                            <p className="text-gray-700">Depuis le Planning, descendez jusqu'à la section « Générer ma semaine » et laissez Nutrimmy configurer automatiquement vos repas pour une semaine complète.</p>
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row items-center md:items-start bg-white pb-10 md:pb-12 text-left">
                        <div className="w-16 md:w-48 flex flex-row items-center mb-4 md:mb-0">
                            <CalendarDateRangeIcon className="text-rose-600 mx-auto w-12 md:w-24" />
                        </div>
                        <div className="w-full md:w-4/5">
                            <h3 className="md:text-2xl font-semibold mb-2 md:mb-4">Étape 5 : Profitez de votre planning personnalisé</h3>
                            <p className="text-gray-700">Votre planning de repas est maintenant prêt ! Profitez d'une semaine organisée de repas parfaitement adaptés à vos besoins nutritionnels.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section id='account-setup' className='py-32 px-2 md:px-0'>
                <div className="container mx-auto">

                    <h2 className="text-2xl md:text-4xl font-bold mb-8 text-left text-gray-900">Rejoignez Nutrimmy et obtenez votre planning dès maintenant.</h2>

                    <div className="flex flex-col md:flex-row">

                        <div className='md:w-[500px] gap-2 p-2 md:p-8 bg-white flex flex-col rounded-lg border border-rose-100'>
                            <div className='mb-4'>
                                <h4 className="md:text-2xl font-bold text-gray-800">Vos informations</h4>
                                <p className='text-gray-500 text-sm'>Nous avons besoin de ces informations pour créer un planning adaptés a vos besoins.</p>
                            </div>

                            <ProfileInputField
                                label="Votre date de naissance"
                                name="birthdate"
                                type="date"
                                value={profile.birthdate || ''}
                                onChange={handleInputChange}
                            />


                            <ProfileInputField
                                label="Votre poids (kg)"
                                name="weight"
                                type="number"
                                value={profile.weight || ''}
                                onChange={handleInputChange}
                            />

                            <ProfileInputField
                                label="Votre taille (cm)"
                                name="height"
                                type="number"
                                value={profile.height || ''}
                                onChange={handleInputChange}
                            />

                            <div className='flex items-center gap-4 mb-4'>
                                <label className=" block font-medium text-gray-700">Vous êtes</label>
                                <RadioButton
                                    radioDisplay={['Un homme', 'Une femme']}
                                    radioValues={['male', 'female']}
                                    selectedValue={profile.gender || 'male'}
                                    setSelectedValue={(value) => { setProfile({ ...profile, gender: value }) }}
                                />
                            </div>

                            <ProfileSelectField
                                label="Activité quotidienne"
                                name="activity_level"
                                value={profile.activity_level}
                                options={[
                                    { label: "Très peu actif (travail de bureau, peu ou pas de sport)", value: 'sedentary' },
                                    { label: "Légèrement actif (marche quotidienne, peu de sport)", value: 'light' },
                                    { label: "Modérément actif (activités régulières, 1-3 séances de sport/semaine)", value: 'moderate' },
                                    { label: "Très actif (travail physique ou 4-5 séances de sport/semaine)", value: 'very_active' },
                                    { label: "Extrêmement actif (sport intense ou travail très physique)", value: 'extremely_active' }

                                ]}
                                onChange={handleInputChange}
                            />

                            <ProfileInputField
                                label="Votre poids à atteindre (kg)"
                                name="weight_goal"
                                type="number"
                                value={profile.weight_goal || ''}
                                onChange={handleInputChange}
                            />

                            {
                                (profile.weight_goal && profile.weight && profile.weight_goal !== profile.weight) ?
                                    <ProfileSelectField
                                        label={'Nombre de kg à' + (profile.weight_goal > profile.weight ? ' gagner' : ' perdre') + ' par semaine'}
                                        name="gain_per_week"
                                        value={profile.gain_per_week || ''}
                                        options={
                                            profile.weight_goal > profile.weight ?
                                                [
                                                    { label: '0.25 kg / semaine', value: 0.25 },
                                                    { label: '0.5 kg / semaine', value: 0.50 },
                                                    { label: '0.75 kg / semaine', value: 0.75 },
                                                    { label: '1 kg / semaine', value: 1.00 }
                                                ] :
                                                [
                                                    { label: '-0.25 kg / semaine', value: -0.25 },
                                                    { label: '-0.5 kg / semaine', value: -0.50 },
                                                    { label: '-0.75 kg / semaine', value: -0.75 },
                                                    { label: '-1 kg / semaine', value: -1.00 }
                                                ]}
                                        onChange={handleInputChange}
                                    />
                                    :
                                    null
                            }
                        </div>

                        <div className='mt-4 md:mt-0 md:px-8'>
                            <h4 className="md:text-2xl font-bold text-gray-800 mb-4">Apprenez en plus sur votre profil</h4>
                            {
                                dietInfo ?
                                    <div>
                                        <DietInfoCard
                                            bmi={dietInfo?.bmi}
                                            bmiReview={getBMIReview(dietInfo?.bmi)}
                                            estimatedGoalTimeline={dietInfo?.estimatedGoalTimeline}
                                            calorieDailyIntake={roundToClosestMultipleOf5(dietInfo?.calorieDailyIntake)}
                                            proteinIntake={roundToClosestMultipleOf5(dietInfo?.proteinIntake)}
                                            carbIntake={roundToClosestMultipleOf5(dietInfo?.carbIntake)}
                                            fatIntake={roundToClosestMultipleOf5(dietInfo?.fatIntake)}
                                        />

                                        <p className='text-gray-700 my-8'>
                                            Connectez-vous pour personnaliser votre planning avec vos propres recettes adaptées sur mesure à vos besoins.
                                            En vous inscrivant, vous pourrez ajuster et optimiser vos recettes en fonction de vos objectifs, avec des quantités adaptées
                                            semaine après semaine pour maximiser vos résultats. Profitez d’un suivi complet de votre alimentation et des besoins de votre corps pour atteindre vos objectifs de façon efficace et précise.
                                        </p>

                                        {
                                            token ?
                                                <div className='bg-gray-900 cursor-pointer transition duration-200 hover:bg-gray-800 text-white font-bold py-2 px-8 rounded-full' onClick={() => navigate('/dashboard')}>
                                                    Acceder a mon planning
                                                </div>
                                                :
                                                <CustomGoogleLogin text='signup_with' />
                                        }

                                    </div>
                                    :
                                    <p className="text-gray-500">Remplissez tous les champs pour acceder aux statistiques.</p>
                            }
                        </div>
                    </div>
                </div>

            </section >
 */}

            {/* FAQ Section */}
            <section className="container mx-auto px-2 md:px-4 md:px-6 py-16 md:pb-32">
                <h2 className="text-2xl md:text-3xl font-semibold text-center mb-8 md:mb-12">Questions fréquentes</h2>
                <div className=" mx-auto grid grid-cols-1 md:grid-cols-2 gap-4">

                    {/* <!-- Première colonne --> */}
                    <div className="space-y-4">
                        <div className='bg-white p-2 md:p-4 rounded-lg bg-white border border-rose-100'>
                            <h3 className="font-semibold md:text-xl text-gray-800 mb-2">Nutrimmy est-elle uniquement pour les sportifs ?</h3>
                            <p className='text-sm text-gray-600 border-l-2 border-rose-300 pl-2'>Non, Nutrimmy s'adresse à tous ceux qui veulent planifier leurs repas de manière efficace et précise, mais elle est particulièrement optimisée pour les besoins des sportifs.</p>
                        </div>
                        <div className='bg-white p-2 md:p-4 rounded-lg bg-white border border-rose-100'>
                            <h3 className="font-semibold md:text-xl text-gray-800 mb-2">Puis-je utiliser mes propres recettes ?</h3>
                            <p className='text-sm text-gray-600 border-l-2 border-rose-300 pl-2'>Oui, vous pouvez ajouter vos propres recettes et les ajuster avec l'optimisateur intelligent.</p>
                        </div>
                        <div className='bg-white p-2 md:p-4 rounded-lg bg-white border border-rose-100'>
                            <h3 className="font-semibold md:text-xl text-gray-800 mb-2">Comment fonctionne la liste de courses ?</h3>
                            <p className='text-sm text-gray-600 border-l-2 border-rose-300 pl-2'>Nutrimmy génère automatiquement une liste de courses en fonction de votre planning hebdomadaire, regroupant tous les ingrédients nécessaires pour la semaine.</p>
                        </div>
                    </div>

                    {/* <!-- Deuxième colonne --> */}
                    <div className="space-y-4">
                        <div className='bg-white p-2 md:p-4 rounded-lg bg-white border border-rose-100'>
                            <h3 className="font-semibold md:text-xl text-gray-800 mb-2">Est-ce que Nutrimmy prend en compte les préférences alimentaires ?</h3>
                            <p className='text-sm text-gray-600 border-l-2 border-rose-300 pl-2'>Actuellement, Nutrimmy ne prend pas en compte les préférences alimentaires, mais vous pouvez créer vos propres recettes et les optimiser en fonction de vos besoins avec l'optimisateur intelligent.</p>
                        </div>
                        <div className='bg-white p-2 md:p-4 rounded-lg bg-white border border-rose-100'>
                            <h3 className="font-semibold md:text-xl text-gray-800 mb-2">Puis-je modifier les portions des repas ?</h3>
                            <p className='text-sm text-gray-600 border-l-2 border-rose-300 pl-2'>Absolument ! Nutrimmy permet d'ajuster les portions pour chaque repas en fonction de vos besoins caloriques.</p>
                        </div>
                        <div className='bg-white p-2 md:p-4 rounded-lg bg-white border border-rose-100'>
                            <h3 className="font-semibold md:text-xl text-gray-800 mb-2">L'application est-elle disponible sur mobile ?</h3>
                            <p className='text-sm text-gray-600 border-l-2 border-rose-300 pl-2'>Oui, vous pouvez y acceder via votre navigateur sur mobile. Nutrimmy n'est aps encore disponible sous forme d'application.</p>
                        </div>
                    </div>

                </div>
            </section>
        </div >
    )
};

export default Landing;
