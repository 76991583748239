import { Meal, MealType } from "../types/mealType";


export const CACHE_KEY_MEAL_PLANNING = 'mealPlanningCache';

export const getCachedMeals = () => {
    const cachedData = localStorage.getItem(CACHE_KEY_MEAL_PLANNING);
    if (cachedData) {
        return JSON.parse(cachedData);
    } else {
        const initialData: { [day: string]: { [key in MealType]?: Meal} } = {};  // Initialize empty structure
        localStorage.setItem(CACHE_KEY_MEAL_PLANNING, JSON.stringify(initialData));
        return initialData;
    }
};

export const getMealCacheTimestamps = (cachedMeals: { [day: string]: { [type: string]: Meal } }) => {
    const cacheTimestamps: { [day: string]: { [type: string]: string } } = {};
    Object.keys(cachedMeals).forEach((day) => {
        cacheTimestamps[day] = {};
        Object.keys(cachedMeals[day]).forEach((mealType) => {
            const lastModified = cachedMeals[day][mealType].last_modified;
            cacheTimestamps[day][mealType] = (typeof lastModified === "string" ? new Date(lastModified) : lastModified).toISOString();
        });
    });
    return cacheTimestamps;
};

export const updateCachedMeal = (meal: Meal) => {
    let cachedData = getCachedMeals();
    cachedData[meal.date.toISOString().split('T')[0]][meal.type] = meal;
    localStorage.setItem(CACHE_KEY_MEAL_PLANNING, JSON.stringify(cachedData));
}

export const saveCachedMeals = (cachedMeals: { [day: string]: { [type: string]: Meal } }) => {
    localStorage.setItem(CACHE_KEY_MEAL_PLANNING, JSON.stringify(cachedMeals));
}