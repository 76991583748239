import { Ingredient, Recipe } from "../types/recipeType";
import { _CARBOHYDRATE, _FAT, _PROTEIN, getEnergyFromNutrientList } from "./foodService";
import { recalculateIngredientNutrimentsQuantity } from "./diet";
import { Meal } from "../types/mealType";
import { Nutriments } from "../types/foodType";
import apiClient from "./apiClient";
import { getCachedMeals, getMealCacheTimestamps, saveCachedMeals } from "./cacheService";
import Cookies from "js-cookie";

export const MEAL_SCHEDULE: { [key: string]: string[] } = {
  "1": ['LUNCH'],
  "2": ['LUNCH', 'DINNER'],
  "3": ['BREAKFAST', 'LUNCH', 'DINNER'],
  "4": ['BREAKFAST', 'LUNCH', 'SNACK_1', 'DINNER'],
  "5": ['BREAKFAST', 'SNACK_1', 'LUNCH', 'SNACK_2', 'DINNER'],
  "6": ['BREAKFAST', 'SNACK_1', 'LUNCH', 'SNACK_2', 'DINNER', 'SNACK_3'],
}

export const getFoodDetails = async (ingredient: Ingredient): Promise<any> => {
  // console.log("getFoodDetails", ingredient);
  apiClient.get(`/api/food/${ingredient.fdc_id}`).then((response) => {
    // console.log(response.data);
    return response.data;
  }).catch((error) => {
    console.error(error);
    return null;
  })
};

export const optimizeMeal = (meal: Meal): Meal => {
  // need ingredients with all nutrients ready
  const nutrients_ratio = {
    calories: meal.calculated_macros.calories / meal.macrosRequired.calories,
    proteins: meal.calculated_macros.proteins / meal.macrosRequired.proteins,
    fats: meal.calculated_macros.fats / meal.macrosRequired.fats,
    carbohydrates: meal.calculated_macros.carbohydrates / meal.macrosRequired.carbohydrates,
  };

  // Reset calculated macros
  meal.calculated_macros = { calories: 0, proteins: 0, fats: 0, carbohydrates: 0 };

  // Update ingredients using map to return a new array
  meal.ingredients = meal.ingredients.map((ingredient) => {
    const newQuantity = Math.round(ingredient.quantity / nutrients_ratio.calories);
    const newMacros = recalculateIngredientNutrimentsQuantity(ingredient, newQuantity);

    // Update the ingredient object with new values
    const updatedIngredient = {
      ...ingredient,
      quantity: newQuantity,
      nutriments: newMacros,
      portion_quantity: Math.round(newQuantity / ingredient.portion_gram_weight),
    };

    // Update calculated macros
    meal.calculated_macros.calories += newMacros.calories;
    meal.calculated_macros.proteins += newMacros.proteins;
    meal.calculated_macros.fats += newMacros.fats;
    meal.calculated_macros.carbohydrates += newMacros.carbohydrates;

    return updatedIngredient;  // Return the updated ingredient to the new array
  });

  return {
    ...meal,
    is_optimized: true,
  };
};


export const optimizeMealsOfDay = (meals: Meal[]): Meal[] => {
  return meals.map((meal) => {
    if (!meal.is_optimized) {
      return optimizeMeal(meal);  // Return the optimized meal
    }
    return meal;  // Return the meal unchanged if already optimized
  });
}

export const calculateMacrosFromIngredients = (ingredients: Ingredient[]): Nutriments => {
  let _nutriments: Nutriments = {
    calories: 0,
    proteins: 0,
    fats: 0,
    carbohydrates: 0,
  };
  ingredients.forEach((ingredient) => {
    _nutriments.calories += ingredient.nutriments.calories;
    _nutriments.proteins += ingredient.nutriments.proteins;
    _nutriments.fats += ingredient.nutriments.fats;
    _nutriments.carbohydrates += ingredient.nutriments.carbohydrates;
  });
  return _nutriments;
}

export const getCalculatedMacrosFromFoodId = async (recipe: Recipe): Promise<Nutriments> => {
  let _nutriments: Nutriments = {
    calories: 0,
    proteins: 0,
    fats: 0,
    carbohydrates: 0,
  };
  // Create an array of promises for each axios request
  const promises = recipe.ingredients.map(async (ingredient) => {
    const response = await apiClient.get(`/api/food/${ingredient.fdc_id}`); // get details
    const food = response.data;
    _nutriments.calories += Math.round(getEnergyFromNutrientList(food.nutrients))

    // Loop through the nutrients of the food and add them to the totals
    food.nutrients.forEach((nutrient: any) => {
      switch (nutrient.nutrient.id) {
        case _PROTEIN:
          _nutriments.proteins += parseInt(nutrient.amount);
          break;
        case _FAT:
          _nutriments.fats += parseInt(nutrient.amount);
          break;
        case _CARBOHYDRATE:
          _nutriments.carbohydrates += parseInt(nutrient.amount);
          break;
        default:
          break;
      }
    });
  });

  // Wait for all axios requests to complete
  await Promise.all(promises);

  return _nutriments;
};

// Serialize recipe for API type
export const serializeMealAPI = (meal: Meal) => {
  // const calculated_macros = await getCalculatedMacrosFromFoodId(recipe);

  return {
    ...meal,
    calculated_macros: meal.calculated_macros,
    date: meal.date.toISOString().split("T")[0],
    last_modified: new Date().toISOString()
  };
};

export const recipeToMeal = (recipe: Recipe, meal: Meal, mealGenerationRunning?: boolean): Meal => {
  const calculated_macros = calculateMacrosFromIngredients(recipe.ingredients);

  return {
    ...meal,
    recipe_name: recipe.name,
    calculated_macros,
    ingredients: recipe.ingredients,
    mealGenerationRunning: mealGenerationRunning,
    is_optimized: false,
    last_modified: new Date()
  }
}

export const getLastCachedMealPlanning = async (days: Date[]) => {

  const cachedMeals = getCachedMeals();
  const cacheTimestamps = getMealCacheTimestamps(cachedMeals);

  // try {
  return apiClient.post('/api/meal/check-updates/', {
    start_date: days[0].toISOString().split('T')[0],
    end_date: days[6].toISOString().split('T')[0],
    cache_timestamps: cacheTimestamps,
  }, {
    headers: {
      Authorization: `Bearer ${Cookies.get('authToken_access')}`,
    },
  }).then((response) => {
    const { meals_to_update, missing_meals } = response.data;

    // console.log("meals_to_update", meals_to_update);
    // console.log("missing_meals", missing_meals);

    // Remove meals from the cache that are in `missing_meals`
    missing_meals.forEach(({ date, type }: { date: string, type: string }) => {
      // console.log("Trying to remove meal from cache", date, days[0].toISOString().split('T')[0], days[6].toISOString().split('T')[0]);
      if (date >= days[0].toISOString().split('T')[0] && date <= days[6].toISOString().split('T')[0]) {
        // console.log("Removing meal from cache", date, type);
        delete cachedMeals[date][type];
      }
    });

    // Add new meals to the cache
    meals_to_update.forEach((updatedMeal: any) => {
      // console.log("Adding meal to cache", updatedMeal.date, updatedMeal.type);
      if (!cachedMeals[updatedMeal.date]) {
        cachedMeals[updatedMeal.date] = {};
      }
      cachedMeals[updatedMeal.date][updatedMeal.type] = updatedMeal;
    });
    saveCachedMeals(cachedMeals);
    return cachedMeals;

    // updateMealCache(cachedMeals);  // Update local cache with received data
  }).catch((error) => {
    console.error('Error fetching updated meals:', error);
    return cachedMeals;
  })
};

export function emptyMeal(meal: Meal): Meal {
  return {
    ...meal,
    is_optimized: false,
    is_eaten: false,
    recipe_name: '',
    recipes: [],
    calculated_macros: {
      calories: 0,
      proteins: 0,
      fats: 0,
      carbohydrates: 0
    },
    ingredients: [],
    mealGenerationRunning: false,
    last_modified: new Date()
  };
}