import React, { useState, ReactNode, useEffect } from 'react';

interface TooltipComponentProps {
    children: ReactNode;
    tooltipText: string;
    className?: string;
    onClick?: () => void;
}

interface TooltipState {
    visible: boolean;
    text: string;
    x: number;
    y: number;
}

const TooltipComponent: React.FC<TooltipComponentProps> = ({ children, tooltipText, className, onClick }) => {
    const [tooltip, setTooltip] = useState<TooltipState>({
        visible: false,
        text: '',
        x: 0,
        y: 0,
    });

    // Function to handle mouse enter
    const handleMouseEnter = () => {
        setTooltip((prev) => ({
            ...prev,
            visible: true,
            text: tooltipText,
        }));
    };

    // Function to handle mouse leave
    const handleMouseLeave = () => {
        setTooltip((prev) => ({
            ...prev,
            visible: false,
        }));
    };

    // Effect to track the mouse position on the entire document
    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            if (tooltip.visible) {
                setTooltip((prev) => ({
                    ...prev,
                    x: event.clientX,
                    y: event.clientY,
                }));
            }
        };

        // Add event listener for mouse movement
        document.addEventListener('mousemove', handleMouseMove);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [tooltip.visible]);

    return (
        <div className={className + " relative"}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onClick}
        >
            <div
            >
                {children}
            </div>

            {tooltip.visible && (
                <div
                    className="absolute w-max z-50 -top-7 left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs p-2 py-1 rounded-lg shadow-md pointer-events-none transition-opacity duration-300"
                // style={{
                //     top: tooltip.y + 15,
                //     left: tooltip.x + 15,
                // }}
                >
                    {tooltip.text}
                </div>
            )}
        </div>
    );
};

export default TooltipComponent;
