// src/App.tsx
import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Landing from './pages/Landing';
import { NotificationProvider } from './provider/NotificationProvider';
import { UserProvider } from './provider/UserProvider';
import Login from './pages/Login';
import CreateProfile from './pages/CreateProfile';
import InAppHandle from './pages/InAppHandle';

const App: React.FC = () => {
  // const isInAppBrowser = /instagram|facebook/.test(navigator.userAgent.toLowerCase());
  // const isInAppBrowser = true;

  return (
    <div>

      <div className=''>
        <NotificationProvider>
          <UserProvider>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/login" element={<Login />} />
              <Route path="/login/create-profile" element={<CreateProfile />} />
              <Route
                path="/dashboard/*"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<Navigate to="/" replace />} /> {/* Redirect unknown paths to Landing */}


            </Routes>
          </UserProvider>
        </NotificationProvider>
      </div>


      <footer className="bg-gray-900 text-white py-12 text-center">
        <div className="container mx-auto px-6 space-y-6">

          {/* Company Logo */}
          <a href="nutrimmy.com" className="text-xl font-bold text-rose-500 lowercase">
            Nutrimmy
          </a>

          {/* Quick Navigation Links */}
          <div className="flex justify-center space-x-8 text-gray-300 text-sm">
            <p>Nous sommes en version BETA, des erreurs peuvent survenir, merci de nous prévenir si vous avez un problème ou des bugs.</p>
            {/* <a href="#features" className="hover:text-white transition duration-200">Fonctionnalités</a>
            <a href="#pricing" className="hover:text-white transition duration-200">Tarification</a>
            <a href="#faq" className="hover:text-white transition duration-200">FAQ</a>
            <a href="#contact" className="hover:text-white transition duration-200">Contact</a> */}
          </div>

          {/* Social Media Links */}
          <div className="flex flex-col justify-center gap-2 mt-4 font-semibold">
            <a href="mailto:nutrimmy@gmail.com" target="_blank" rel="noopener noreferrer" className="hover:text-rose-500 transition duration-200">nutrimmy@gmail.com</a>
            <a href="https://www.instagram.com/nutrimmy.app/" target="_blank" rel="noopener noreferrer" className="hover:text-rose-500 transition duration-200">instagram.com/nutrimmy.app</a>
          </div>

          {/* Copyright and Disclaimer */}
          <p className="text-gray-400 text-xs mt-8">
            &copy; 2024 Nutrimmy. Tous droits réservés.
          </p>
        </div>
      </footer>

    </div>
  );
};

export default App;