// src/pages/Profile.tsx
import React from 'react';
import EditProfile, { DietInfoCard } from '../components/EditProfile';
import { getBMIReview } from '../services/diet';
import { ArrowLeftStartOnRectangleIcon } from '@heroicons/react/24/solid'
import { useUserProvider } from '../provider/UserProvider';
import { calculateAge } from '../services/utils';

export interface UserProfile {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  display_name: string;
  birthdate: string;
  gender: string;
  height: number;
  weight: number;
  regime: string;
  objective: string;
  gain_per_week: number;
  activity_level: string;
  weight_goal: number;
  meal_schedule: string;
  user_created: boolean;
  profile_created: boolean;
  is_google_user: boolean;
}

const Profile: React.FC = () => {
  const { profile, dietInfo, handleLogout } = useUserProvider();

  return (
    <div className="p-2 md:p-8">
      <div className="mb-8">

        <h1 className="text-2xl font-bold">Voici votre résumé {profile.first_name}</h1>
        <span className="text-gray-500">{profile.email}</span>
        <div className="flex gap-2 mt-4">
          <span className='px-4 py-1 bg-rose-100 border-b border-rose-300 text-rose-700 w-fit'>
            {calculateAge(profile.birthdate) || 'N/A'} ans
          </span>
          <span className='px-4 py-1 bg-rose-100 border-b border-rose-300 text-rose-700 w-fit'>
            {profile.weight || 'N/A'} kg
          </span>
          <span className='px-4 py-1 bg-rose-100 border-b border-rose-300 text-rose-700 w-fit'>
            {profile.height || 'N/A'} cm
          </span>
          <span className='px-4 py-1 bg-rose-100 border-b border-rose-300 text-rose-700 w-fit'>
            {profile.gender === 'male' ? 'Homme' : 'Femme'}
          </span>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-8">

        <EditProfile />

        <DietInfoCard
          bmi={dietInfo?.bmi}
          bmiReview={getBMIReview(dietInfo?.bmi)}
          estimatedGoalTimeline={dietInfo?.estimatedGoalTimeline}
          calorieDailyIntake={dietInfo?.calorieDailyIntake}
          proteinIntake={dietInfo?.proteinIntake}
          carbIntake={dietInfo?.carbIntake}
          fatIntake={dietInfo?.fatIntake}
        />

      </div>

      <button onClick={handleLogout} className="w-full md:w-fit flex justify-center gap-2 mt-32 items-center bg-red-500 text-white px-4 py-2 shadow rounded hover:bg-red-600">
        <ArrowLeftStartOnRectangleIcon className="h-5 w-5" />
        <p>Se déconnecter</p>
      </button>
    </div>
  );
};

export default Profile;
