import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { Recipe } from '../types/recipeType';
import apiClient from '../services/apiClient';
import { useUserProvider } from './UserProvider';
import { useNotification } from './NotificationProvider';
import { serializeRecipeAPI, serializeRecipeDetailsFromAPI, updateRecipeFavorite } from '../services/recipeService';

interface RecipeContextType {
    recipesPrivate: Recipe[];
    setRecipesPrivate: React.Dispatch<React.SetStateAction<Recipe[]>>;
    recipesPublic: Recipe[];
    setRecipesPublic: React.Dispatch<React.SetStateAction<Recipe[]>>;
    recipeProviderReady: boolean;
    createRecipe: (recipe: Recipe) => Promise<string>;
    updateRecipe: (recipe: Recipe) => Promise<string>;
    getRecipeDetails: (id: number) => Promise<Recipe | null>;
    deleteRecipe: (recipe: Recipe) => Promise<void>;
    handleFavoriteRecipe: (recipe: Recipe) => void;
}

const RecipeContext = createContext<RecipeContextType>({
    recipesPrivate: [],
    setRecipesPrivate: () => { },
    recipesPublic: [],
    setRecipesPublic: () => { },
    recipeProviderReady: false,
    createRecipe: async () => { return "NOT_CREATED" },
    updateRecipe: async () => { return "NOT_UPDATED" },
    getRecipeDetails: async () => null,
    deleteRecipe: async () => { },
    handleFavoriteRecipe: async () => { },
});

export const useRecipeProvider = () => useContext(RecipeContext);


const CACHE_KEY_PUBLIC_RECIPES = 'publicRecipesCache';
const CACHE_EXPIRATION_KEY = 'publicRecipesCacheExpiration';
const CACHE_DURATION = 60 * 60 * 1000 * 24; // 1 day in milliseconds

export const RecipeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const [recipesPrivate, setRecipesPrivate] = useState<Recipe[]>([]);
    const [recipesPublic, setRecipesPublic] = useState<Recipe[]>([]);
    const [recipeProviderReady, setRecipeProviderReady] = useState(false);

    const { addNotification } = useNotification();
    const { authToken_access } = useUserProvider();

    const fetchPublicRecipes = async () => {
        try {
            const response = await apiClient.get('/api/recipe/', {
                params: {
                    public_only: true,
                },
                headers: {
                    Authorization: `Bearer ${authToken_access}`,
                },

            });
            const _recipe = response.data as Recipe[];
            _recipe.sort((a, b) => b.type.localeCompare(a.type));
            _recipe.sort((a, b) => Number(b.is_favorite) - Number(a.is_favorite));

            setRecipesPublic(_recipe);

            // Cache public recipes with an expiration timestamp
            localStorage.setItem(CACHE_KEY_PUBLIC_RECIPES, JSON.stringify(_recipe));
            localStorage.setItem(CACHE_EXPIRATION_KEY, (Date.now() + CACHE_DURATION).toString());

        } catch (err: any) {
            console.error(err);
        }
    }

    const fetchPrivateRecipes = async () => {
        try {
            const response = await apiClient.get('/api/recipe/', {
                params: {
                    private_only: false,
                },
                headers: {
                    Authorization: `Bearer ${authToken_access}`,  // Adjust this if you're using another method for auth
                },
            });

            const _recipe = response.data as Recipe[];
            _recipe.sort((a, b) => b.type.localeCompare(a.type));
            // setRecipesPublic(_recipe.filter((recipe) => recipe.is_public));
            setRecipesPrivate(_recipe.filter((recipe) => !recipe.is_public));
        } catch (err: any) {
            console.error(err);
        }
    };


    const createRecipe = async (recipe: Recipe): Promise<string> => {
        if (recipe.name.trim() === "") {
            addNotification('warning', 'Renseignez le nom de la recette.');
            return "NOT_CREATED";
        }
        if (recipe.ingredients.length === 0) {
            addNotification('warning', 'Ajoutez au moins un ingrédient.');
            return "NOT_CREATED";
        }
        return apiClient.post('/api/recipe/', serializeRecipeAPI(recipe), {
            headers: {
                'Authorization': `Bearer ${authToken_access}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            if (response.status === 201) {
                setRecipesPrivate((prevRecipes) => [...prevRecipes, recipe]);
                return "CREATED";
            } else {
                addNotification('error', 'Une erreur est survenue lors de l\'ajout de la recette');
                return "NOT_CREATED";
            }
        }).catch((error) => {
            console.error(error);
            return "NOT_CREATED";
        })

    };

    const updateRecipe = async (recipe: Recipe): Promise<string> => {
        if (recipe.name.trim() === "") {
            addNotification('warning', 'Renseignez le nom de la recette');
            return "NOT_UPDATED";
        }
        if (recipe.ingredients.length === 0) {
            addNotification('warning', 'Ajoutez au moins un ingrédient.');
            return "NOT_UPDATED";
        }
        return apiClient.put(`/api/recipe/${recipe.id}/`, serializeRecipeAPI(recipe), {
            headers: {
                'Authorization': `Bearer ${authToken_access}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            if (response.status === 200) {
                setRecipesPrivate((prevRecipes) => prevRecipes.map((r) => (r.id === recipe.id ? recipe : r)));
                return "UPDATED";
            } else {
                addNotification('error', 'Une erreur est survenue lors de la mise à jour de la recette.');
                return "NOT_UPDATED";
            }
        }).catch((error) => {
            console.error('Error updating recipe:', error);
            return "NOT_UPDATED";
        })
    };

    const handleFavoriteRecipe = (recipe: Recipe) => {
        console.log("handleFavoriteRecipe", recipe);

        updateRecipeFavorite(recipe, authToken_access).then((res: string) => {
            if (recipe.is_public) {
                console.log("recipesPublic", res);

                setRecipesPublic((prevRecipes) => prevRecipes.map((r) => (r.id === recipe.id ? { ...r, is_favorite: !r.is_favorite } : r)));
                if (res === "ADDED_FAVORITE")
                    localStorage.setItem(CACHE_KEY_PUBLIC_RECIPES, JSON.stringify(recipesPublic.map((r) => (r.id === recipe.id ? { ...r, is_favorite: true } : r))));
                if (res === "REMOVED_FAVORITE")
                    localStorage.setItem(CACHE_KEY_PUBLIC_RECIPES, JSON.stringify(recipesPublic.map((r) => (r.id === recipe.id ? { ...r, is_favorite: false } : r))));
            } else {
                setRecipesPrivate((prevRecipes) => prevRecipes.map((r) => (r.id === recipe.id ? { ...r, is_favorite: !r.is_favorite } : r)));
                // localStorage.setItem(CACHE_KEY_PRIVATE_RECIPES, JSON.stringify(recipesPrivate));
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    const deleteRecipe = async (recipe: Recipe) => {
        apiClient.delete(`/api/recipe/${recipe.id}/`, {
            headers: {
                Authorization: `Bearer ${authToken_access}`,  // Adjust this if you're using another method for auth
            },
        }).then(() => {
            addNotification('success', 'Recette supprimée avec succès');
            setRecipesPrivate((prevRecipes) => prevRecipes.filter((r) => r.id !== recipe.id));
        }).catch((error) => {
            console.error('Error deleting recipe:', error);
            addNotification('error', 'Une erreur est survenue lors de la suppression de la recette.');
        })
    }

    const getRecipeDetails = async (id: number) => {
        return apiClient.get(`/api/recipe/${id}/`, {
            headers: {
                'Authorization': `Bearer ${authToken_access}`,
                'Content-Type': 'application/json',
            },
        }).then((recipeJSON) => {
            return serializeRecipeDetailsFromAPI(recipeJSON.data) as Recipe;
        }).catch((error) => {
            console.error('Error fetching recipe details:', error);
            return null;
        })
    }

    useEffect(() => {
        const cachedPublicRecipes = localStorage.getItem(CACHE_KEY_PUBLIC_RECIPES);
        const cachedExpiration = localStorage.getItem(CACHE_EXPIRATION_KEY);

        // if (cachedPublicRecipes && cachedExpiration) {
        if (cachedPublicRecipes && cachedExpiration && Date.now() < parseInt(cachedExpiration, 10)) {
            const expiration = Number(cachedExpiration);
            if (Date.now() < expiration) {
                const cachedRecipes = JSON.parse(cachedPublicRecipes) as Recipe[];
                setRecipesPublic(cachedRecipes.sort((a, b) => Number(b.is_favorite) - Number(a.is_favorite)));
                setRecipeProviderReady(true);
            }
        } else {
            fetchPublicRecipes();
            setRecipeProviderReady(true);
        }

        fetchPrivateRecipes();
        setRecipeProviderReady(true);
    }, [])

    // useEffect(() => {
    //     setLoading(true);

    //     // Check cache validity
    //     const cachedPublicRecipes = localStorage.getItem(CACHE_KEY_PUBLIC_RECIPES);
    //     const cacheExpiration = localStorage.getItem(CACHE_EXPIRATION_KEY);

    //     if (cachedPublicRecipes && cacheExpiration && Date.now() < parseInt(cacheExpiration, 10)) {
    //         // Load from cache if valid
    //         setRecipesPublic(JSON.parse(cachedPublicRecipes));
    //         setLoading(false);
    //     } else {
    //         // Fetch from server if cache is invalid or not present
    //         fetchPublicRecipes();
    //     }
    // }, []);

    return (
        <RecipeContext.Provider value={{
            recipesPrivate,
            setRecipesPrivate,
            recipesPublic,
            setRecipesPublic,
            recipeProviderReady,
            createRecipe,
            updateRecipe,
            getRecipeDetails,
            deleteRecipe,
            handleFavoriteRecipe
        }}>
            {children}
        </RecipeContext.Provider>
    );
};