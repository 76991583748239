import { Nutriments } from "./foodType";
import { Ingredient, Recipe } from "./recipeType";

export const BREAKFAST: string = 'BREAKFAST';
export const LUNCH: string = 'LUNCH';
export const DINNER: string = 'DINNER';
export const SNACK_1: string = 'SNACK_1';
export const SNACK_2: string = 'SNACK_2';
export const SNACK_3: string = 'SNACK_3';

export type MealType = 'BREAKFAST' | 'LUNCH' | 'DINNER' | 'SNACK_1' | 'SNACK_2' | 'SNACK_3';

export interface Meal {
    id?: number;
    type: MealType;
    date: Date;
    recipe_name: string;
    is_optimized: boolean;
    is_eaten: boolean;
    macrosRequired: Nutriments;
    calculated_macros: Nutriments;
    recipes: Recipe[];
    ingredients: Ingredient[];
    mealGenerationRunning?: boolean; // To know if meal generation so not save the meal direclty
    last_modified: Date;
}

export const MEAL_TYPE_FR = {
    BREAKFAST: 'Petit déjeuner',
    LUNCH: 'Déjeuner',
    DINNER: 'Dîner',
    SNACK_1: 'Collation',
    SNACK_2: 'Collation',
    SNACK_3: 'Collation',
}