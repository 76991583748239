import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useUserProvider } from '../provider/UserProvider';
import apiClient from '../services/apiClient';
import { ChevronLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid';
import { useNotification } from '../provider/NotificationProvider';
import { LoadingFullScreen } from '../components/Loading';

const CreateProfile: React.FC = () => {
    const [isCreatingProfile, setIsCreatingProfile] = useState(false);

    const { authToken_access, profile, setProfile } = useUserProvider();
    const navigate = useNavigate();
    const { addNotification } = useNotification();

    // Validation Schema
    const validationSchema = Yup.object().shape({
        display_name: Yup.string()
            .max(50, 'Le pseudo doit contenir au maximum 50 caractères'),
        birthdate: Yup.date()
            .required('Veuillez entrer votre date de naissance')
            .max(new Date('2023-01-01'), 'La date de naissance est trop recente')
            .min(new Date('1900-01-01'), 'La date de naissance est trop ancienne'),
        weight: Yup.number()
            .required('Veuillez entrer votre poids')
            .min(10, 'Le poids doit être supérieur à 10 kg')
            .max(500, 'Le poids doit être inférieur à 500 kg'),
        height: Yup.number()
            .required('Veuillez entrer votre taille')
            .min(30, 'La taille doit être supérieure à 50 cm')
            .max(300, 'La taille doit être inférieure à 250 cm'),
        gender: Yup.string()
            .required('Veuillez sélectionner votre sexe')
            .oneOf(['male', 'female'], 'Valeur invalide pour le sexe'),
        activity_level: Yup.string()
            .required('Veuillez sélectionner votre activité physique')
            .oneOf(
                ['sedentary', 'light', 'moderate', 'very_active', 'extremely_active'],
                'Valeur invalide pour le niveau d’activité'
            ),
        weight_goal: Yup.number()
            .required('Veuillez entrer votre objectif de poids')
            .min(10, 'Le poids cible doit être supérieur à 10 kg')
            .max(500, 'Le poids cible doit être inférieur à 500 kg'),
        gain_per_week: Yup.number()
            .test(
                'required-gain-per-week',
                'Veuillez sélectionner votre gain par mois',
                function (value) {
                    const { weight, weight_goal } = this.parent;
                    if (weight && weight_goal && weight !== weight_goal) {
                        return value !== undefined;
                    }
                    return true; // No need to validate if weight and weight_goal are equal
                }
            )
            .oneOf(
                [-1, -0.75, -0.5, -0.25, 0.25, 0.5, 0.75, 1],
                'Valeur invalide pour le gain par mois'
            ),
    });

    const formik = useFormik({
        initialValues: {
            display_name: profile?.display_name || '',
            birthdate: profile?.birthdate || '',
            weight: profile?.weight || '',
            height: profile?.height || '',
            gender: profile?.gender || '',
            activity_level: profile?.activity_level || '',
            weight_goal: profile?.weight_goal || '',
            gain_per_week: profile?.gain_per_week || '',
        },
        validationSchema,
        onSubmit: (values) => {
            setIsCreatingProfile(true);
            if (profile.user_created) {
                apiClient
                    .put('/api/profile/', values, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${authToken_access}`,
                        },
                    })
                    .then((response) => {
                        if (response) {
                            setProfile({
                                ...profile,
                                display_name: values.display_name,
                                birthdate: values.birthdate,
                                weight: Number(values.weight),
                                height: Number(values.height),
                                weight_goal: Number(values.weight_goal),
                                activity_level: values.activity_level,
                                gain_per_week: Number(values.gain_per_week),
                                meal_schedule: '4'
                            })
                            addNotification('success', 'Bienvenue sur Nutrimmy!');
                            navigate('/dashboard/profile'); // Navigate to another page after success
                        }
                    })
                    .catch((error) => {
                        console.error('Error updating profile:', error);
                    }).finally(() => {
                        setIsCreatingProfile(false);
                    })
            } else {
                setProfile({
                    ...profile,
                    display_name: values.display_name,
                    birthdate: values.birthdate,
                    weight: Number(values.weight),
                    height: Number(values.height),
                    weight_goal: Number(values.weight_goal),
                    activity_level: values.activity_level,
                    gain_per_week: Number(values.gain_per_week),
                });
                navigate('/login/create-profile/save');
                setIsCreatingProfile(false);
            }
        },
    });

    useEffect(() => {
        apiClient.get('/api/profile/check',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken_access}`,
                },
            }
        ).then((response) => {
            if (!response.data.user_created) {
                addNotification('warning', 'Vous n\'avez pas encore de compte. Veuillez vous inscrire pour continuer.', 7000);
                navigate('/login');
            }
        })
    }, [profile.user_created]);

    if (isCreatingProfile) {
        return <LoadingFullScreen text="Création de votre profil..." />
    }

    return (
        <div className='bg-nutrimmy-bg min-h-screen flex flex-col items-center justify-center p-2 pt-16 md:p-16'>
            <div
                className="bg-nutrimmy-bg/40 w-full fixed top-0 left-0 p-2 flex items-center backdrop-blur gap-2 cursor-pointer text-gray-800 transition duration-200 hover:text-gray-800"
                onClick={() => navigate(-1)}
            >
                <ChevronLeftIcon className="h-4 w-4" />
                Retour
            </div>

            <form
                onSubmit={formik.handleSubmit}
                className="form flex flex-col w-full md:w-[400px] mb-4"
            >
                <label className="text-xl md:text-2xl font-semibold text-gray-800 mb-2">
                    Votre pseudo ou prénom
                </label>
                <input
                    type="text"
                    name="display_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.display_name}
                    placeholder="Votre pseudo ou prénom"
                    className="p-2 border-b border-rose-300"
                />
                {formik.touched.display_name && formik.errors.display_name && (
                    <p className="text-red-500">{formik.errors.display_name}</p>
                )}

                <label className="text-xl md:text-2xl font-semibold text-gray-800 mb-2 mt-8">
                    Votre date de naissance
                </label>
                <input
                    type="date"
                    name="birthdate"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.birthdate}
                    className="mb-2 p-2 border-b border-rose-300"
                />
                {formik.touched.birthdate && formik.errors.birthdate && (
                    <p className="text-red-500">{formik.errors.birthdate}</p>
                )}

                <label className="text-xl md:text-2xl font-semibold text-gray-800 mb-2 mt-8">
                    Votre poids actuel
                </label>
                <input
                    type="number"
                    name="weight"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.weight}
                    placeholder="Votre poids en kg"
                    className="mb-2 p-2 border-b border-rose-300"
                />
                {formik.touched.weight && formik.errors.weight && (
                    <p className="text-red-500">{formik.errors.weight}</p>
                )}

                <label className="text-xl md:text-2xl font-semibold text-gray-800 mb-2 mt-8">
                    Votre taille
                </label>
                <input
                    type="number"
                    name="height"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.height}
                    placeholder="Votre taille en cm"
                    className="mb-2 p-2 border-b border-rose-300"
                />
                {formik.touched.height && formik.errors.height && (
                    <p className="text-red-500">{formik.errors.height}</p>
                )}

                <label className="text-xl md:text-2xl font-semibold text-gray-800 mb-2 mt-8">
                    Votre sexe
                </label>
                <select
                    name="gender"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.gender}
                    className="mb-2 p-2 border-b border-rose-300"
                >
                    <option value="">Sélectionnez votre sexe</option>
                    <option value="male">Homme</option>
                    <option value="female">Femme</option>
                </select>
                {formik.touched.gender && formik.errors.gender && (
                    <p className="text-red-500">{formik.errors.gender}</p>
                )}

                <label className="text-xl md:text-2xl font-semibold text-gray-800 mb-2 mt-8">
                    Votre activité physique
                </label>
                <select
                    name="activity_level"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.activity_level}
                    className="mb-2 p-2 border-b border-rose-300"
                >
                    <option value="">Sélectionnez votre niveau d'activité physique</option>
                    <option value="sedentary">Très peu actif (travail de bureau, peu ou pas de sport)</option>
                    <option value="light">Légèrement actif (marche quotidienne, peu de sport)</option>
                    <option value="moderate">Modéramment actif (activités régulières, 1-3 séances de sport/semaine)</option>
                    <option value="very_active">Très actif (travail physique ou 4-5 séances de sport/semaine)</option>
                    <option value="extremely_active">Extrêmement actif (sport intense ou travail très physique)</option>
                </select>
                {formik.touched.activity_level && formik.errors.activity_level && (
                    <p className="text-red-500">{formik.errors.activity_level}</p>
                )}

                <label className="text-xl md:text-2xl font-semibold text-gray-800 mb-2 mt-8">
                    Votre objectif de poids
                </label>
                <input
                    type="number"
                    name="weight_goal"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.weight_goal}
                    placeholder="Votre objectif de poids en kg"
                    className="mb-2 p-2 border-b border-rose-300"
                />
                {formik.touched.weight_goal && formik.errors.weight_goal && (
                    <p className="text-red-500">{formik.errors.weight_goal}</p>
                )}

                {formik.values.weight_goal && formik.values.weight !== formik.values.weight_goal && (
                    <>
                        <label className="text-xl md:text-2xl font-semibold text-gray-800 mb-2 mt-8">
                            Votre {formik.values.weight < formik.values.weight_goal ? 'gain' : 'perte'} par mois
                        </label>
                        <select
                            name="gain_per_week"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.gain_per_week}
                            className="mb-2 p-2 border-b border-rose-300"
                        >
                            {formik.values.weight < formik.values.weight_goal ? (
                                <>
                                    <option value="">Sélectionnez votre gain par mois</option>
                                    <option value="0.25">1 kg / mois</option>
                                    <option value="0.5">2 kg / mois</option>
                                    <option value="0.75">3 kg / mois</option>
                                    <option value="1">4 kg / mois</option>
                                </>
                            ) : (
                                <>
                                    <option value="">Sélectionnez votre perte par mois</option>
                                    <option value="-0.25">-1 kg / mois</option>
                                    <option value="-0.5">-2 kg / mois</option>
                                    <option value="-0.75">-3 kg / mois</option>
                                    <option value="-1">-4 kg / mois</option>
                                </>
                            )}
                        </select>
                        {formik.touched.gain_per_week && formik.errors.gain_per_week && (
                            <p className="text-red-500">{formik.errors.gain_per_week}</p>
                        )}
                    </>
                )}

                <button
                    type="submit"
                    className="bg-rose-500 flex items-center gap-2 justify-center border-2 border-rose-500 hover:bg-nutrimmy-bg hover:text-rose-500 transition duration-200 text-white font-bold py-2 px-4 mt-8 rounded"
                >
                    Obtenir mon planning
                    <ArrowRightIcon className="h-4 w-4" />
                </button>
                {formik.errors && Object.keys(formik.errors).length > 0 && formik.touched && Object.keys(formik.touched).length > 0 && (
                    <p className="text-red-500 text-sm mt-4">
                        Il y a des erreurs dans les champs que vous avez saisis. Veuillez vérifier les champs en rouge.
                    </p>
                )}
            </form>
        </div>
    );
};

export default CreateProfile;