import { Nutriments } from "../types/foodType"

export const _ENERGY = 1008;
export const _ENERGY_ATWATER_GENERAL = 2047;
export const _ENERGY_ATWATER_SPECIFIC = 2048;
export const _PROTEIN = 1003;
export const _FAT = 1004;
export const _CARBOHYDRATE = 1005;

export const getNutrientFromNutrientList = (nutrientList: any, id: number) => {
    return nutrientList.find((currentNutrient: any) => currentNutrient.nutrient.id === id);
}

export const getEnergyFromNutrientList = (nutrientList: any) => {
    let _energy = nutrientList.find((currentNutrient: any) => currentNutrient.nutrient.id === _ENERGY)
    if (_energy) return Math.round(_energy.amount)

    _energy = nutrientList.find((currentNutrient: any) => currentNutrient.nutrient.id === _ENERGY_ATWATER_GENERAL)
    if (_energy) return Math.round(_energy.amount)

    _energy = nutrientList.find((currentNutrient: any) => currentNutrient.nutrient.id === _ENERGY_ATWATER_SPECIFIC)
    if (_energy) return Math.round(_energy.amount)

    return 0
}

export const keepInterestedNutrientsOnly = (food_details: any): Nutriments => {
    return {
        calories: Math.round(getEnergyFromNutrientList(food_details.nutrients)),
        proteins: Math.round(getNutrientFromNutrientList(food_details.nutrients, _PROTEIN)?.amount),
        fats: Math.round(getNutrientFromNutrientList(food_details.nutrients, _FAT)?.amount),
        carbohydrates: Math.round(getNutrientFromNutrientList(food_details.nutrients, _CARBOHYDRATE)?.amount),
    }
}

export const FOOD_CATEGORY_FR: { [key: string]: string } = {
    "1": "Produits laitiers et œufs",
    "2": "Épices et herbes",
    "3": "Aliments pour bébés",
    "4": "Graisses et huiles",
    "5": "Produits de volaille",
    "6": "Soupes, sauces et jus de viande",
    "7": "Saucisses et viandes de déjeuner",
    "8": "Céréales de petit-déjeuner",
    "9": "Fruits et jus de fruits",
    "10": "Produits de porc",
    "11": "Légumes et produits de légumes",
    "12": "Produits à base de noix et de graines",
    "13": "Produits de bœuf",
    "14": "Boissons",
    "15": "Poissons et produits de fruits de mer",
    "16": "Légumineuses et produits de légumineuses",
    "17": "Produits d'agneau, de veau et de gibier",
    "18": "Produits de boulangerie",
    "19": "Confiseries",
    "20": "Grains de céréales et pâtes",
    "21": "Restauration rapide",
    "22": "Repas, plats et accompagnements",
    "23": "En-cas",
    "24": "Aliments des Amérindiens/Autochtones de l'Alaska",
    "25": "Aliments de restaurant",
    "26": "Base de données de produits alimentaires de marque",
    "27": "Matériaux de contrôle de qualité",
    "28": "Boissons alcoolisées"
};
